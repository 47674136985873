import { Component, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { EnvironmentService } from '../../services/environment.service';

@Component({
  selector: 'app-dev-tools',
  templateUrl: './dev-tools.component.html',
  styleUrls: ['./dev-tools.component.scss'],
})
export class DevToolsComponent implements OnInit {
  public messages;
  public isDevToolsEnabled = false;
  public showDevTools = false;

  constructor(private environmentService: EnvironmentService) {}

  ngOnInit() {
    if (!Capacitor.isNativePlatform() || !this.environmentService.getEnvironmentProperty('showDevTool')) {
      return false;
    }

    this.messages = [];
    Capacitor.isLoggingEnabled = true;

    window.console.log = (...args) => {
      this.saveLog('log', args);
    };
    window.console.error = (...args) => {
      this.saveLog('error', args);
    };
    window.console.warn = (...args) => {
      this.saveLog('warn', args);
    };
    window.console.debug = (...args) => {
      this.saveLog('debug', args);
    };
    window.console.info = (...args) => {
      this.saveLog('info', args);
    };

    (window as any).Capacitor.logToNative = (result) => {
      this.createLogToNative(result);
    };

    (window as any).Capacitor.logFromNative = (result) => {
      this.createLogFromNative(result);
    };

    this.isDevToolsEnabled = true;
  }

  saveLog(type, msg: Array<any>) {
    if (msg.length > 1) {
      const message = {
        type: type,
        text: msg[0],
        description: JSON.stringify(msg),
        collapsed: true,
      };
      this.messages.push(message);
    } else {
      this.messages.push({
        type: type,
        text: msg,
      });
    }
  }

  toggleDevTools() {
    this.showDevTools = !this.showDevTools;
  }

  toggleMsgDescription(msg) {
    msg.collapsed = !msg.collapsed;
  }

  createLogFromNative(result) {
    const message = {
      type: 'native',
      text: "From native: " + result.pluginId + '.' + result.methodName,
      description: null,
      collapsed: true,
    };

    if (result.success === false) {
      message.type = 'error';
      message.text += " Error: " + result.error;
    } else {
      message.description = JSON.stringify(result.data);
    }
    this.messages.push(message);
  }

  createLogToNative(result) {
    const message = {
      type: 'native',
      text: "To native: " + result.pluginId + '.' + result.methodName,
      description: JSON.stringify(result),
      collapsed: true,
    };
    this.messages.push(message);
  }
}
