import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular-ivy';
import { Replay } from '@sentry/replay';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import {Integration} from '@sentry/types';
import { datadogRum } from '@datadog/browser-rum';
import {datadogLogs, LogsEvent} from '@datadog/browser-logs';
import packageJson from '../package.json';

/** Initialize sentry. Should be before Angular initialization */
let sentryIntegration: Integration[] = [
  new SentryAngular.BrowserTracing({
    routingInstrumentation: SentryAngular.routingInstrumentation,
  })
];
if(environment.sentry.replay && environment.sentry.enabled) {
  sentryIntegration = sentryIntegration.concat(
    new Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    })
  );
}

// if dsn value empty or null, we don't init Sentry
if(environment.sentry.dsn && environment.sentry.enabled) {
  Sentry.init(
    {
      dsn: environment.sentry.dsn,
      release: packageJson.version,
      integrations: sentryIntegration as any,
      tracesSampleRate: environment.sentry.tracesSampleRate,
      tracePropagationTargets: environment.sentry.tracePropagationTargets,
      replaysSessionSampleRate: environment.sentry.replaysSessionSampleRate,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: environment.sentry.replaysOnErrorSampleRate,
      environment: environment.sentry.environment,
      beforeSend(event, hint) {
        // Do not sent 401 errors to sentry
        const error = hint.originalException;
        if (
          error instanceof Error &&
          error.message &&
          (error.message.match(/401\d/g))
        ) {
          return null;
        }
        return event;
      },
    },
    SentryAngular.init
  );
}

if (environment.datadog.clientToken && environment.datadog.enabled) {
  datadogLogs.init({
    //applicationId: environment.datadog.applicationId,
    clientToken: environment.datadog.clientToken,
    beforeSend: (log: LogsEvent) => {
      // discard 401 network errors
      if (log.http && log.http.status_code === 401) {
        return false;
      }
      return  true;
    },
    site: environment.datadog.site,
    service: environment.datadog.service,
    env: environment.datadog.env,
    forwardErrorsToLogs: environment.datadog.forwardErrorsToLogs,
  });
  datadogRum.init({
    applicationId: environment.datadog.applicationId,
    clientToken: environment.datadog.clientToken,
    site: environment.datadog.site,
    service: environment.datadog.service,
    env: environment.datadog.env,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: environment.datadog.version,
    sessionSampleRate: environment.datadog.sessionSampleRate,
    sessionReplaySampleRate: environment.datadog.sessionReplaySampleRate,
    trackUserInteractions: environment.datadog.trackUserInteractions,
    trackResources: environment.datadog.trackResources,
    trackLongTasks: environment.datadog.trackLongTasks,
    defaultPrivacyLevel: environment.datadog.defaultPrivacyLevel,
    allowedTracingUrls: environment.datadog.allowedTracingUrls
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

// Call the element loader after the platform has been bootstrapped
// Needed for Capacitor Camera plugins
defineCustomElements(window);
