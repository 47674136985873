import { Injectable, NgZone } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ApiService } from './api.service';
import { HelperService } from './helper.service';
import { OfflineHandlerService } from './offline/handler.service';
import {Badge, SetBadgeOptions} from '@capawesome/capacitor-badge';
import { NavController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { EventsService } from './events.service';
import { ObjectableUtil } from '../utils/objectable.util';
import {PushNotifications, PushNotificationSchema, Token} from '@capacitor/push-notifications';
import {ActionPerformed, LocalNotifications} from '@capacitor/local-notifications';
import {PrivacyPolicyUpdatedComponent} from '../modals/privacy-policy-updated/privacy-policy-updated.component';
import {SystemNotificationComponent} from '../modals/system-notification/system-notification.component';
import {ModalService} from './modal.service';
import {INotification} from '../interfaces/notification.interface';
import {NotificationsClient} from './clients/cp-api/notifications/notifications.client';


@Injectable({
  providedIn: 'root'
})

export class NotificationService {
  private user;
  private totalUnread = 0; // Holds count of unread messages and notifications

  private isPushNotifcationInitialized = false;
  private isLocalNotifcationInitialized = false;
  private tokenStored = false;

  public token: string;
  public selectedCompany;

  public notifications = [];
  public messages = [];
  public unreadNotifications = [];
  public unreadMessages = [];

  public totalUnreadCount = new BehaviorSubject<number>(0);
  public unreadMessagesCount = new BehaviorSubject<number>(0);
  public unreadNotificationsCount = new BehaviorSubject<number>(0);

  private currentPushNotification = null;

  private supportedNotificationTypes = [
    'AssignedUser',
    'DueNotification',
    'MentionedUser',
    'MessageNotification',
    'PerformAvailableNotification',
    'IncidentStatusNotification',
    'ResourceUpdatedNotification',
    'TaskNotification',
  ];

  constructor(
      private apiService: ApiService,
      private helperService: HelperService,
      private offlineHandlerService: OfflineHandlerService,
      private events: EventsService,
      private navController: NavController,
      private zone: NgZone,
      private modalService: ModalService,
      private notificationsClient: NotificationsClient
  ) {
    // Subscribes to event that is sent when going from offline -> online in offline handler
    this.events.subscribe('offline:goOnline', async () => {
      await this.setupNotifications(this.user);
    });

    // Subscribes to event when going offline
    this.events.subscribe('offline:activated', async () => {
      await this.clear(); // Clear all when going to offline mode
    });
  }


  /**
   * Runs after user clicks on an native push notification and routes to correct endpoint in the app.
   *
   * @param notification
   */
  private async visitNotification(notification) {
    if (!this.helperService.isInitialized.getValue()) {
      this.currentPushNotification = notification;
      return Promise.resolve();
    }

    let data = null;
    if (notification.hasOwnProperty('extra')) {
      data = notification.extra;
    } else {
      data = notification.data;
    }
    const url = this.getNavigateUrl(data);
    switch (data.object_type) {
      case 'App\\Models\\Message':
        this.events.publish('sideMenu:showNotificationCenter');
        break;
      default:
        if (url) {
          this.navController.navigateForward(url);
        }
        break;
    }

    this.currentPushNotification = null;
  }

  /**
   * Runs after logging in to get token and call function to setup all listeners for push notifications
   */
  public async init(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      await this.initPushNotification();
      await this.initLocalNotification();
    }
  }
  /** Init CheckPermission & Request Permission mandatory since Google API 33 */
  private async initPushNotification() {
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }
    if (permStatus.receive === 'granted') {
      this.addPushNotificationListeners();
      this.tokenStored = false;
      await PushNotifications.register();
    } else {
      console.error('User denied permissions!');
    }
    await PushNotifications.register();
  }

  /** Init CheckPermission & Request Permission mandatory since Google API 33 */
  private async initLocalNotification() {
    let permStatus = await LocalNotifications.checkPermissions();
    if (permStatus.display === 'prompt') {
      permStatus = await LocalNotifications.requestPermissions();
    }
    if (permStatus.display === 'granted') {
      this.addLocalNotificationListeners();
    } else {
      console.error('User denied permissions!');
    }
  }

  /**
   * Adding listeners for push notifications
   */
  public addPushNotificationListeners(): void {
    if (Capacitor.isNativePlatform()) { // Should only be called on native devices, not browsers version
      // Checks if eventlistenrs is allready setup for notifications
      if (this.isPushNotifcationInitialized === false) {

        PushNotifications.addListener('registration', (token: Token) => {
          if (!this.tokenStored || this.token !== token.value) {
            this.token = token.value;
            this.storePushNotificationToken();
            this.tokenStored = true;
          }
        });

        // Listener for notification registration errors
        PushNotifications.addListener('registrationError', (error: any) => error);

        // Listener for receiving push notifications
        PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
          this.unreadNotifications.push(notification.id); // Adds the new notification to unreadNotifications when received from fcm
          this.setUnread(++this.totalUnread); // Increase the total of unread notifications by 1 when receiving a push notification

          this.showNotification(notification); // Call function to display toast notification when app is in foreground
        });

        // Triggers when clicking on a native push notification when app is in background mode
        PushNotifications.addListener('pushNotificationActionPerformed', (action) => {
          this.offlineHandlerService.isOfflineEnable().then(isOffline => {
            if (!isOffline) {
              this.zone.run(() => {
                this.visitNotification(action.notification); // Calls notification routing functionality
              });
            }
          });
        });

        // Marks that eventlinsteners is initialized and  prevents more eventlisteners from being set up for the service.
        this.isPushNotifcationInitialized = true;
      }
    }
  }

  /**
   * Adding listeners for Local notifications
   */
  public addLocalNotificationListeners(): void {
    if (Capacitor.isNativePlatform()) {
      if (this.isLocalNotifcationInitialized === false) {
        LocalNotifications.addListener('localNotificationActionPerformed', (action: ActionPerformed) => {
          this.offlineHandlerService.isOfflineEnable().then(isOffline => {
            if (!isOffline) {
              this.zone.run(() => {
                this.visitNotification(action.notification); // Calls notification routing functionality
              });
            }
          });
        });

        // Marks that eventlinsteners is initialized and  prevents more eventlisteners from being set up for the service.
        this.isLocalNotifcationInitialized = true;
      }
    }
  }

  public checkPushNotifications() {
    if (this.currentPushNotification) {
      this.visitNotification(this.currentPushNotification);
    }
  }

  /**
   *
   *
   * @param user
   */
  public async setupNotifications(user): Promise<any> {
    if (Capacitor.isNativePlatform() && await Badge.isSupported()) {
      const hasBadgePermission = await Badge.checkPermissions();
      if (hasBadgePermission.display === 'prompt') {
        await Badge.requestPermissions();
      }
      await Badge.clear();
    }

    this.user = user;
    this.selectedCompany = {id: user.id, name: user.company.name};
    this.addPushNotificationListeners();
    this.addLocalNotificationListeners();

    return Promise.all([
      this.getMessages(user.id),
      this.getNotifications(user.id)
    ]);
  }

  public async setupSystemNotifications(): Promise<void> {
    const isOffline = await this.offlineHandlerService.isOfflineEnable();
    if (isOffline) {
      return;
    }

    const notifications = await this.notificationsClient.getSystemNotifications();
    if (!notifications?.data?.length) {
      return;
    }
    notifications.data
      .filter((notification: INotification) => notification.read_at === null)
      .forEach((notification: INotification) => this.showSystemNotification(notification));
  }

  /**
   * Keeps track of all unread notifications/messages
   * Handles functionality for showing correct number on hte app icon badge
   *
   * @param unreadCount
   */
  public async setUnread(unreadCount: number) {
    const isOffline = await this.offlineHandlerService.isOfflineEnable();

    if (!isOffline) {
      this.totalUnreadCount.next(unreadCount);
      this.unreadMessagesCount.next(this.unreadMessages.length);
      this.unreadNotificationsCount.next(this.unreadNotifications.length);

      if (Capacitor.isNativePlatform()) {
        // Change badge count on app icon
        const badgeOption: SetBadgeOptions = { count: this.totalUnread}
        await Badge.set(badgeOption);
      }
    }
  }

  /**
   * Parsing notifications and display a toast message
   * @param notification
   */
  private showNotification(notification): void {
    LocalNotifications.schedule({
      notifications: [
        {
          title: notification.data.title,
          body: notification.data.body,
          id: 1,
          schedule: { at: new Date(Date.now() + 500) },
          sound: null,
          attachments: null,
          actionTypeId: '',
          extra: notification.data
        }
      ]
    });
  }

  /**
   * Changing company, clears messages/notifications
   * Call api to get new messages/notifications from selected company
   *
   * @param company
   */
  public changeCompany(company): Promise<any> {
    this.selectedCompany = company;
    this.unreadNotifications = [];
    this.unreadMessages = [];
    this.totalUnread = 0;
    this.setUnread(this.totalUnread);


    return Promise.all([
      this.getMessages(company.id),
      this.getNotifications(company.id)
    ]);
  }

  /**
   * Retrieves messages
   *
   * If userId is not specified, it will take messages from default user company
   * @param userId
   */
  private getMessages(userId = null): Promise<any> {
    const append = (userId !== null) ? '?user_id=' + userId : '';

    return this.apiService.get('/messages' + append).then(messagesResponse => {
      if (messagesResponse.data.length > 0) {
        this.messages = messagesResponse.data;
        this.offlineHandlerService.isOfflineEnable().then(isOffline => {
          if (!isOffline) {
            this.messages.filter(message => message.read === false).map(m => m.id).forEach(id => {
              if (!this.unreadMessages.includes(id)) {
                this.unreadMessages.push(id);
                this.setUnread(++this.totalUnread);
              }
            });
          }
        });
      } else {
        this.messages = [];
      }

      return { messages: this.messages, data: messagesResponse };
    });
  }

  /**
   * Loading more messages depending on page number and user
   *
   * @param userId
   *
   * The specific page to load
   * @param page
   */
  public getMoreMessages(userId, page): Promise<any> {
    let append = '?page=' + page;
    if (userId !== null) {
      append += '&user_id=' + userId;
    }

    return this.apiService.get('/messages' + append).then(messagesResponse => {
      if (messagesResponse.data.length > 0) {
        messagesResponse.data.filter(message => message.read === false).map(m => m.id).forEach(id => {
          if (!this.unreadMessages.includes(id)) {
            this.unreadMessages.push(id);
            this.setUnread(++this.totalUnread);
          }
        });
      }

      return { messages: messagesResponse.data, data: messagesResponse };
    });
  }

  /**
   * Retrieves notifications
   *
   * If userId is not specified, it will take messages from default user company
   * @param userId
   */
  private async getNotifications(userId = ''): Promise<any> {
    let append;
    if (userId !== null) {
      append = '&user_id=' + userId;
    }

    const isOffline = await this.offlineHandlerService.isOfflineEnable();

    if (isOffline) {
      return { notifications: [], data: {} };
    } else {
      return this.apiService.get('/users/me/notifications?exclude=messages' + append).then(notificationsResponse => {
        if (notificationsResponse.data.length > 0) {
          this.notifications = notificationsResponse.data.filter(notification => this.isSupportedNotificationType(notification.type));

          this.notifications.filter(notification => notification.read_at === null).map(n => n.id).forEach(id => {
            if (!this.unreadNotifications.includes(id)) {
              this.unreadNotifications.push(id);
              this.setUnread(++this.totalUnread);
            }
          });
        } else {
          this.notifications = [];
        }

        return { notifications: this.notifications, data: notificationsResponse };
      });
    }
  }

  /**
   * Loading more notifications depending on page number and user
   *
   * @param userId
   *
   * The specific page to load
   * @param page
   */
  public getMoreNotifications(userId: number, page: number): Promise<any> {
    let append = '&page=' + page;
    if (userId !== null) {
      append += '&user_id=' + userId;
    }

    return this.apiService.get('/users/me/notifications?exclude=messages' + append).then(response => ({
        notifications: (response.data || []).filter(notification => this.isSupportedNotificationType(notification.type)),
        data: response
      }));
  }

  public async readSystemNotification(id: string): Promise<void> {
    await this.notificationsClient.readSystemNotification(id);
  }

  private showSystemNotification(notification: INotification) {
    switch (notification.type) {
      case 'App\\Notifications\\PrivacyPolicyUpdated':
        this.modalService.open({
          component: PrivacyPolicyUpdatedComponent,
          componentProps: {
            notification: notification
          },
          backdropDismiss: false
        });
        break;
      case 'App\\Notifications\\SystemNotification':
        this.modalService.open({
          component: SystemNotificationComponent,
          componentProps: {
            notification: notification
          },
          backdropDismiss: false
        });
        break;
    }
  }

  /**
   * Save token to db
   */
  private async storePushNotificationToken() {
    if (Capacitor.isNativePlatform()) {
      const isOffline = await this.offlineHandlerService.isOfflineEnable();

      if (!isOffline) {
        const type = (Capacitor.getPlatform() === 'ios') ? this.helperService.protocol.deviceTypes.IOS : this.helperService.protocol.deviceTypes.ANDROID;
        this.apiService.post('/users/me/device', { token: this.token, type: type });
      }
    }
  }

  /**
   * Delete token from db
   */
  public async deletePushNotificationToken() {
    if (Capacitor.isNativePlatform()) {
      return this.apiService.delete('/users/me/device', {forceOnline: true})
        .then(() => true)
        .catch(() => false);
    } else {
      return Promise.resolve(true);
    }
  }

  /**
   * Mark a specific message as read, even supports change company
   * @param message
   */
  public async markMessagesRead(message) {
    const isOffline = await this.offlineHandlerService.isOfflineEnable();

    if (!isOffline) {
      // If user has changed company, it will change url and add user parameter to it
      const url = (this.selectedCompany !== null) ? '/messages/' + message.id + '?user_id=' + this.selectedCompany.id : '/messages/' + message.id;

      this.unreadMessages.splice(this.unreadMessages.findIndex(id => id === message.id), 1); // Removes the message from unread messages
      this.setUnread(--this.totalUnread);

      // Send request to api to set message as read
      this.apiService.put(url, message);
    }
  }

  /**
   * Marks all notifications as read, even supports changed company functionality
   *
   * @param userId
   */
  public async markAllNotificationsRead(userId = null) {
    const isOffline = await this.offlineHandlerService.isOfflineEnable();

    if (!isOffline) {
      if (this.unreadNotifications) {
        let append = '';
        if (userId != null) {
          append = '?user_id=' + userId;
        }

        this.totalUnread = this.totalUnread - this.unreadNotifications.length;
        this.unreadNotifications = [];
        this.setUnread(this.totalUnread);

        this.apiService.put('/users/me/notifications/unread/read' + append, {});
      }
    }
  }

  /**
   * Clear everything in notification service and clear the app badge number
   */
  public async clear(): Promise<void> {
    this.messages = [];
    this.notifications = [];
    this.unreadMessages = [];
    this.unreadNotifications = [];

    this.totalUnreadCount.next(0);
    this.unreadMessagesCount.next(0);
    this.unreadNotificationsCount.next(0);

    if (Capacitor.isNativePlatform()) {
      await Badge.clear();
    }
  }

  /**
   * Triggers when pusher sends NotificationCreated message
   */
  public async onNotificationCreated() {
    await this.setupNotifications(this.user);
  }

  private isSupportedNotificationType(type) {
    return this.supportedNotificationTypes.indexOf(type.replace('App\\Notifications\\', '')) >= 0;
  }

  public getNavigateUrl(data) {
    if (!data.object_id || !data.object_type) {
      return null;
    }

    if (data.objectable && typeof data.objectable === 'string') {
      data.objectable = JSON.parse(data.objectable);
    }

    const id = data.object_id;

    switch (data.object_type) {
      case 'App\\Models\\Check':
        return ['/check-view/' + id];
      case 'App\\Models\\Deviation':
        return ['/deviation', {id: id, type: 'deviation'}];
      case 'App\\Models\\IndependentDeviation':
        return ['/deviation', {id: id, type: 'independent'}];
      case 'App\\Models\\Inspection':
        return ['/inspection/' + id];
      case 'App\\Models\\ChecklistVariant':
        if (!data.objectable || !data.objectable.id) {
          return null;
        }
        return ['/check-variant/' + id, {
          objectId: data.objectable.id,
          objectType: ObjectableUtil.isUnit(data.objectable) ? 'unit' : 'round'
        }];
      case 'App\\Models\\Service':
        return ['/service-perform/' + id];
      case 'App\\Models\\ServiceInterval':
        if (!data.objectable || !data.objectable.id) {
          return null;
        }
        return ['/service-interval/' + data.objectable.id + '/' + id];
      case 'App\\Models\\Incidents\\Incident':
        return ['/incident/' + id];
      case 'App\\Models\\CheckConnection':
        if (!data.objectable) {
          return null;
        }
        return ['/check-variant/' + data.objectable.checklist_variant_id, {
          objectId: data.objectable.objectable_id,
          objectType: data.objectable.objectable_type === 'App\\Models\\Unit' ? 'unit' : 'round',
          parentCheck: data.objectable.check_id
        }];
      case 'Modules\\PlannerTool\\Dtos\\TaskDto':
        return this.getPlannerToolTaskUrl(data.resource);
    }

    return null;
  }

  private getPlannerToolTaskUrl(data) {
    if (typeof data === 'string') {
      // push-notification lib doesn't convert sub-properties to object
      data = JSON.parse(data);
    }

    if (
      !data || !data.objectable || !data.objectable.id || !data.objectable.type ||
      !data.origin || !data.origin.type || !data.origin.id
    ) {
      return null;
    }

    switch (data.origin.type) {
      case 'ServiceInterval':
        return ['/service-interval/' + data.objectable.id + '/' + data.origin.id];
      case 'ChecklistVariant':
        return ['/check-variant/' + data.origin.id, {
          objectId: data.objectable.id,
          objectType: data.objectable.type === 'Unit' ? 'unit' : 'round'
        }];
    }
    return null;
  }
}
