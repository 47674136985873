import {Injectable} from '@angular/core';
import {ApiService} from '../../../api.service';
import RouteService from '../../../route.service';
import {IPublicToken} from '../../../../interfaces/public-token.interface';

@Injectable({
  providedIn: 'root'
})
export class PublicTokensClient {
  constructor(
    private apiService: ApiService,
    private routeService: RouteService,
  ) {
  }

  /** Check public token and get public info about it */
  public get(token: string): Promise<IPublicToken> {
    return this.apiService.get(
      this.routeService.makeUrl(
        this.routeService.PATHS.publicTokens.getByToken,
        {token}
      ),
      {},
      { disableErrorHandler: true }
    );
  }
}
