import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { OfflineHandlerService } from '../../services/offline/handler.service';
import { EventsService } from '../../services/events.service';
import {AppUpdaterService} from '../../services/app-updater.service';

@Component({
  selector: 'app-offline-banner',
  templateUrl: './offline-banner.component.html',
  styleUrls: ['./offline-banner.component.scss']
})
export class OfflineBannerComponent implements OnInit, OnDestroy {
  @Input() public type = 'bottomBanner';

  public isOffline = false;
  public isOfflineExpireSoon = false;
  public formatRemainTime = '0';
  public isOfflineCacheNewerAppVersion = true;

  private checkInterval = null;
  private onOfflineActivatedHandler = null;

  constructor(
    private events: EventsService,
    private offlineHandlerService: OfflineHandlerService,
    private appUpdaterService: AppUpdaterService,
  ) { }

  ngOnInit() {
    this.initOfflineBanner();
  }

  ngOnDestroy() {
    this.events.unsubscribe('offline:activated', this.onOfflineActivatedHandler);

    this.stopCheckRemainTime();
  }

  /**
   * Init offline banner
   * @return {Promise<void>}
   */
  async initOfflineBanner() {
    this.onOfflineActivatedHandler = this.onOfflineActivated.bind(this);

    this.events.subscribe('offline:activated', this.onOfflineActivatedHandler);

    this.isOffline = await this.offlineHandlerService.isOfflineEnable();

    if (this.type === 'bottomBanner') {
      this.toggleBanner();
    }

    if (this.isOffline) {
      this.startCheckRemainTime();
      await this.checkOfflineCacheAppVersion();
    }
  }

  /**
   * Triggered when offline mode activated/deactivated
   * @param active
   */
  private onOfflineActivated(active) {
    this.isOffline = active;
    if (this.isOffline) {
      this.startCheckRemainTime();
    } else {
      this.stopCheckRemainTime();
    }
    this.checkOfflineCacheAppVersion();
    this.toggleBanner();
  }

  /**
   * Display offline banner based on current status of offline mode
   */
  private toggleBanner(): void {
    if (this.isOffline) {
      document.body.classList.add('offline-banner-visible');
      this.checkRemainTime();
    } else {
      document.body.classList.remove('offline-banner-visible');
    }
  }

  /**
   * Start timer to update remaining time
   */
  private startCheckRemainTime(): void {
    if (this.checkInterval) {
      return;
    }

    const intervalTime = 60 * 1000; // 1 minute
    this.checkInterval = setInterval(this.checkRemainTime.bind(this), intervalTime);
    this.checkRemainTime();
  }

  /**
   * Stop timer to update remaining time
   */
  private stopCheckRemainTime(): void {
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
      this.checkInterval = null;
    }
  }

  /**
   * Check remaining time and update banner
   * @return {Promise<void>}
   */
  private async checkRemainTime() {
    const remainTime = await this.offlineHandlerService.getRemainTime();
    this.formatRemainTime = this.offlineHandlerService.formatRemainTime(remainTime);
    this.isOfflineExpireSoon = this.offlineHandlerService.willExpireSoon(remainTime);
  }

  private async checkOfflineCacheAppVersion() {
    const offlineAppVersion = await this.offlineHandlerService.getData('app-version');
    this.isOfflineCacheNewerAppVersion = this.appUpdaterService.isSameVersion(offlineAppVersion);
  }
}
