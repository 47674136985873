import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { ApiService } from './api.service';
import { HelperService } from './helper.service';
import { LangService } from './lang.service';
import { OfflineHandlerService } from './offline/handler.service';
import { NavController } from '@ionic/angular';
import { EnvironmentService } from './environment.service';
import { Capacitor } from '@capacitor/core';
import { AppFeature } from '../enums';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';
import { HomeScreenService } from './home-screen.service';
import { EventsService } from './events.service';
import { IntercomService } from './intercom.service';
import { IOAuthRequest } from '../interfaces/oauth-request.interface';
import { IOAuthResponse } from '../interfaces/oauth-response.interface';
import { AccessTokenService } from './access-token.service';
import { ToastService } from './toast.service';
import * as SentryAngular from '@sentry/angular-ivy';
import {HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {datadogRum} from '@datadog/browser-rum';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private user = null;

  private allowedFeatures;

  public companySettings = {
    showDeviationThumbs: false,
    allowAssignDeviationResponsible: false,
    showCheckStatusbarObjectSelector: false,
    refillOdometerOptional: false,
    deviationTagMandatory: false,
    deviationDescriptionMandatory: false,
    deviationDueDateMandatory: false,
    hideDeviationDownTime : false,
    hideExternalIncidents: false,
    isDeviationCategoryMandatory: false, // Mandatory categories on deviation form
    isDeviationSubCategoryMandatory: false, // Mandatory sub categories on deviation form
    isDeviationPositionMandatory: false,
    allowEditingOthersControlPoints: false,
    allowRfid: false,
    allowFleetOptimization: false,
    isWorkTimeStartEndDateEnabled: false,
    isWorTimeTypeRequired: false,
    isWorkTimeActivityRequired: false,
    timeZone: '',
  };

  public companyStrings = <any>{};

  constructor(
    private storage: Storage,
    private apiService: ApiService,
    private helperService: HelperService,
    private langService: LangService,
    private offlineHandlerService: OfflineHandlerService,
    private navController: NavController,
    private router: Router,
    private events: EventsService,
    private environmentService: EnvironmentService,
    private notificationService: NotificationService,
    private homeScreenService: HomeScreenService,
    private intercomService: IntercomService,
    private accessTokenService: AccessTokenService,
    private toastService: ToastService,
  ) {

    this.resetAllowedFeatures();

    this.events.subscribe('api:unauthenticated', () => {
      if (this.router.url !== '/login') {
        this.logout();
      }
    });
  }

  public async initService() {
    await this.accessTokenService.initService();
    this.accessTokenService.setAuthService(this);
  }

  /**
   * @param jwt
   */
  public async getJwtUserSame(jwt: string) {
    const externalUserId = await this.storage.get('external_user_id');
    return <boolean>await this.apiService.get('/jwt/user', {jwt: jwt}).then(response => {
      if (response.external_user && response.external_user.external_user_id) {
        return response.external_user.external_user_id === externalUserId;
      } else {
        return false;
      }
    });
  }

  public setExternalUserID(externalUserId: string) {
    this.storage.set('external_user_id', externalUserId);
  }

  public loginJWT(jwt: string, type = null): Promise<any> {
    if (!type) {
      type = this.helperService.protocol.ExternalTypes.BEEKEEPER;
    }

    return this.fetchOauthToken('jwt', {
      username: jwt,
      password: null,
      external_type: type,
    });
  }

  public login(username: string, password: string): Promise<any> {
    return this.fetchOauthToken('password', {
      username: username,
      password: password,
    });
  }

  public async loginPublicUser(username: string, code: string, publicToken: string): Promise<any> {
    try {
      return await this.fetchOauthToken('public_user', {
        username: username,
        code: code,
        public_token: publicToken
      });
    } catch (error) {
      const message = error.status === 401 ?
        this.langService.t('passwords.code.wrong') :
        this.apiService.getErrorMessageByHttpCode(error.status);
      throw new HttpErrorResponse({
        error: message,
        status: error.status
      });
    }
  }

  public async switchUser(userId: number): Promise<any> {
    const postData = this.getOauthData('shared_user');
    postData.user_id = userId;

    const switchResponse = await this.apiService.post('/users/switch', postData);

    // Clear storage and reset offline mode by calling logout
    const result = await this.logout(null);
    // Check if user approved the logout
    if (result !== true) {
      return;
    }

    // Set new token to authService from api /switch response
    return this.handleOauthResponse(switchResponse);
  }

  /**
   * Attempt to logout with confirmation dialog
   * @return
   */
  public async attemptLogout(): Promise<any> {
    const allowed = await this.offlineLogoutCheck();
    if (allowed !== true) {
      return Promise.resolve(false);
    }

    try {
      if (await this.isAuthenticated()) {
        // To delete device token we still need to be logged with an api token
        await this.notificationService.deletePushNotificationToken();
        await this.apiService.post('/logout', {}, {disableErrorHandler: true, forceOnline: true});
      }
    } catch (exception) {
      // suppress exceptions when logout attempt with invalid token
    }

    return this.logout();
  }

  public async logout(navigateUrl: string = '/login'): Promise<any> {
    await this.helperService.showLoader();
    return this.onLogout(navigateUrl);
  }

  public async logoutPublicUser() {
    try {
      if (await this.isAuthenticated()) {
        await this.apiService.post('/logout', {}, {disableErrorHandler: true, forceOnline: true});
      }
    } catch (exception) {
      // suppress exceptions when logout attempt with invalid token
    }
    return this.logout(null);
  }

  private async onLogout(navigateUrl): Promise<any> {
    await this.apiService.cancelRequests();
    await this.accessTokenService.clear();
    await this.clearStorage();
    await this.homeScreenService.clearAllCache();

    this.helperService.resetInstance();
    this.notificationService.clear(); // Clear notifications data and remove pushnotifications eventlisteners
    this.intercomService.disconnectFromIntercom();
    if (environment.sentry.enabled) {
      SentryAngular.setUser(null);
    }
    if (environment.datadog.enabled) {
      datadogRum.clearUser();
    }
    this.events.publish('user:logout');

    await this.helperService.dismissLoader();

    if (navigateUrl) {
      // Checks if logout should navigate or not
      return this.navController.navigateRoot([navigateUrl], {animated: true, animationDirection: 'back'});
    }

    // Resolves logout without redirection
    return Promise.resolve(true);
  }

  public clearStorage(): Promise<any> {
    this.user = null;

    // Holds specific storage keys to be removed
    const promises = [
      this.storage.remove('external_user_id'),
      this.langService.clearTranslationsCache()
    ];

    return Promise.all(promises);
  }

  public isAuthenticated(): Promise<boolean> {
    return this.storage.get('token')
      .then(token => {
        return token !== null;
      })
      .catch(() => {
        return false;
      });
  }

  public getCurrentUser(refresh = false): Promise<any> {
      if (this.user === null || refresh) {
        return this.apiService.get('/users/me')
          .then(userResponse => {
            this.user = userResponse;
            if (userResponse.is_external && userResponse.external_user && userResponse.external_user.external_user_id) {
              this.setExternalUserID(userResponse.external_user.external_user_id);
            }
            return this.user;
          })
          .catch(error => error);
      } else {
        return Promise.resolve(this.user);
      }
  }

  /**
   * Set what features are allowed for current user
   */
  public setAllowedFeatures() {
    this.resetAllowedFeatures();

    if (!this.user || !this.user.role) {
      return;
    }

    if (this.user.role.hierarchy !== this.helperService.protocol.roles.INCIDENT_USER) {
      if (!this.user.company.admin_disabled_functions.includes(this.helperService.protocol.adminFunctions.STANDARD)) {

        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.PERFORM_CHECK)) {
          this.allowedFeatures[AppFeature.CHECKS] = true;
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.REFILLS)) {
          this.allowedFeatures[AppFeature.REFILLS] = true;
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.UNITS_AND_ROUNDS)) {
          this.allowedFeatures[AppFeature.UNITS_ROUNDS] = true;

          if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.UNITS_ROUNDS_CREATE)) {
            this.allowedFeatures[AppFeature.UNITS_ROUNDS_CREATE] = true;
          }
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.SERVICES)) {
          this.allowedFeatures[AppFeature.SERVICES] = true;
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.SERVICE_CATEGORIES)) {
          this.allowedFeatures[AppFeature.SERVICE_CATEGORIES] = true;
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.SERVICE_DOWNTIME)) {
          this.allowedFeatures[AppFeature.SERVICE_DOWNTIME] = true;
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.SERVICE_ODOMETER)) {
          this.allowedFeatures[AppFeature.SERVICE_ODOMETER] = true;
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.SERVICE_DATE_AND_TIME)) {
          this.allowedFeatures[AppFeature.SERVICE_DATE_AND_TIME] = true;
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.SERVICE_ASSETS_USED)) {
          this.allowedFeatures[AppFeature.SERVICE_ASSETS_USED] = true;
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.SERVICE_WORK_TIME)) {
          this.allowedFeatures[AppFeature.SERVICE_WORKTIME] = true;
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.SERVICE_REFILL)) {
          this.allowedFeatures[AppFeature.SERVICE_REFILL] = true;
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.SERVICE_ITEMS)) {
          this.allowedFeatures[AppFeature.SERVICE_ITEMS] = true;
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.INSPECTIONS_CERTIFICATES)) {
          this.allowedFeatures[AppFeature.INSPECTIONS] = true;
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.DEVIATIONS)) {
          this.allowedFeatures[AppFeature.DEVIATIONS] = true;
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.DEVIATIONS_CATEGORIES)) {
          this.allowedFeatures[AppFeature.DEVIATIONS_CATEGORIES] = true;
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.DEVIATIONS_DOWNTIME)) {
          this.allowedFeatures[AppFeature.DEVIATIONS_DOWNTIME] = true;
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.DEVIATIONS_WHO_AND_WHEN)) {
          this.allowedFeatures[AppFeature.DEVIATIONS_WHO_AND_WHEN] = true;
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.DEVIATIONS_ODOMETER)) {
          this.allowedFeatures[AppFeature.DEVIATIONS_ODOMETER] = true;
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.DEVIATIONS_DUE_WITHIN)) {
          this.allowedFeatures[AppFeature.DEVIATIONS_DUE_WITHIN] = true;
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.DEVIATIONS_RESPONSIBLE)) {
          this.allowedFeatures[AppFeature.DEVIATIONS_RESPONSIBLE] = true;
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.DEVIATIONS_DATE_AND_TIME)) {
          this.allowedFeatures[AppFeature.DEVIATIONS_DATE_AND_TIME] = true;
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.DEVIATIONS_ASSETS_USED)) {
          this.allowedFeatures[AppFeature.DEVIATIONS_ASSETS_USED] = true;
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.DEVIATIONS_WORK_TIME)) {
          this.allowedFeatures[AppFeature.DEVIATIONS_WORKTIME] = true;
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.DEVIATIONS_REFILL)) {
          this.allowedFeatures[AppFeature.DEVIATIONS_REFILL] = true;
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.DEVIATIONS_ITEM)) {
          this.allowedFeatures[AppFeature.DEVIATIONS_ITEMS] = true;
        }
        if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.ITEMS)) {
          this.allowedFeatures[AppFeature.ARTICLES] = true;
        }
      }
    }
    if (
        !this.user.company.app_disabled_functions.includes(this.helperService.protocol.appFunctions.INCIDENTS)
        && !this.user.company.admin_disabled_functions.includes(this.helperService.protocol.adminFunctions.INCIDENTS)
    ) {
      this.allowedFeatures[AppFeature.INCIDENTS] = true;
    }
    if (!this.user.company.app_admin_disabled_functions.includes(this.helperService.protocol.appAndAdminFunctionsTitles.DOCUMENTS)) {
      this.allowedFeatures[AppFeature.INFO] = true;
    }
    if (!this.user.company.app_disabled_functions.includes(this.helperService.protocol.appFunctions.OFFLINE) &&
      (Capacitor.isNativePlatform() || this.environmentService.getEnvironmentProperty('browserOfflineMode'))
    ) {
      this.allowedFeatures[AppFeature.OFFLINE_MODE] = true;
    }
    if (this.environmentService.getEnvironmentProperty('offlineBackupImport')) {
      this.allowedFeatures[AppFeature.OFFLINE_BACKUP_IMPORT] = true;
    }
    if (!this.user.company.app_disabled_functions.includes(this.helperService.protocol.appFunctions.UPDATE_NOTICE)) {
      this.allowedFeatures[AppFeature.UPDATE_NOTICE] = true;
    }

    if (this.user.company.enabled_functions && this.user.company.enabled_functions.length > 0) {
      if (this.user.company.enabled_functions.includes(this.helperService.protocol.enabledFunctions.SAML)) {
        this.allowedFeatures[AppFeature.SAML] = true;
      }
      if (this.user.company.enabled_functions.includes(this.helperService.protocol.enabledFunctions.OEM_COMPANY)) {
        this.allowedFeatures[AppFeature.OEM_COMPANY] = true;
      }

      if (this.user.company.enabled_functions.includes(this.helperService.protocol.enabledFunctions.RFID)) {
        this.allowedFeatures[AppFeature.RFID] = !this.user.rfid_disabled;
      }

      if (this.user.company.enabled_functions.includes(this.helperService.protocol.enabledFunctions.VIDEO)) {
        this.allowedFeatures[AppFeature.VIDEO] = true;
      }

      if (this.user.company.enabled_functions.includes(this.helperService.protocol.enabledFunctions.FLEET_OPTIMIZATION)) {
        this.allowedFeatures[AppFeature.FLEET_OPTIMIZATION] = true;
      }

      if (this.user.company.enabled_functions.includes(this.helperService.protocol.enabledFunctions.ENABLE_CASCADING_POSITIONS)) {
        this.allowedFeatures[AppFeature.ENABLE_CASCADING_POSITIONS] = true;
      }

      if (this.user.company.enabled_functions.includes(this.helperService.protocol.enabledFunctions.DEVIATION_TEMPLATES)) {
        this.allowedFeatures[AppFeature.DEVIATION_TEMPLATES] = true;
      }
    }
    if (!this.user.company.admin_disabled_functions.includes(this.helperService.protocol.adminFunctions.PLANNER_TOOL)) {
      this.allowedFeatures[AppFeature.PLANNER_TOOL] = true;
    }
  }

  /**
   * Get what features are allowed for current user
   */
  public getAllowedFeatures() {
    return this.allowedFeatures;
  }

  /**
   * Check is some feature allowed
   * @param feature
   * @return {boolean}
   */
  public isFeatureAllowed(feature): boolean {
    return this.allowedFeatures[feature] || false;
  }

  /**
   * Reset current settings for allowed features
   */
  private resetAllowedFeatures() {
    this.allowedFeatures = {};
    Object.keys(AppFeature).forEach(feature => {
      this.allowedFeatures[AppFeature[feature]] = false;
    });
  }

  /**
   * Set current company settings
   */
  public setCompanySettings() {
    if (!this.user || !this.user.company) {
      return;
    }

    /**
     * Make a map between source(server) and destination(front) settings.
     * @see companySettings
     */
    const srcDstKeys = {};
    const dstKeys = Object.keys(this.companySettings);
    [ // Keep order of the object fields
      this.helperService.protocol.companySettings.SHOW_DEVIATION_THUMBS,
      this.helperService.protocol.companySettings.ALLOW_USERS_ASSIGN_DEVIATION_RESPONSIBLE,
      this.helperService.protocol.companySettings.SHOW_CHECK_STATUSBAR_OBJECT_SELECTOR,
      this.helperService.protocol.companySettings.REFILL_ODOMETER_OPTIONAL,
      this.helperService.protocol.companySettings.DEVIATION_TAG_MANDATORY,
      this.helperService.protocol.companySettings.DEVIATION_DESCRIPTION_MANDATORY,
      this.helperService.protocol.companySettings.DEVIATION_DUE_DATE_MANDATORY,
      this.helperService.protocol.companySettings.DISABLE_DEVIATIONS_DOWN_TIME,
      this.helperService.protocol.companySettings.HIDE_EXTERNAL_INCIDENTS,
      this.helperService.protocol.companySettings.MANDATORY_DEVIATION_CATEGORIES,
      this.helperService.protocol.companySettings.DEVIATION_SUB_CATEGORY_MANDATORY,
      this.helperService.protocol.companySettings.MANDATORY_DEVIATION_POSITIONS,
      this.helperService.protocol.companySettings.ALLOW_EDITING_OTHERS_CONTROL_POINTS,
      this.helperService.protocol.companySettings.ALLOW_RFID,
      this.helperService.protocol.companySettings.FLEET_OPTIMIZATION,
      this.helperService.protocol.companySettings.ENABLE_WORKTIME_START_END_DATE,
      this.helperService.protocol.companySettings.WORKTIME_TYPE_REQUIRED,
      this.helperService.protocol.companySettings.WORKTIME_ACTIVITY_REQUIRED,
    ]
      .forEach((srcKey, index) => {
        srcDstKeys[srcKey] = dstKeys[index];
      });

    if (this.user.company.company_settings && this.user.company.company_settings.length) {
      this.user.company.company_settings.forEach(setting => {
        const enabled = 'true' === setting.value;

        if (this.helperService.protocol.companySettings.ALLOW_USERS_ASSIGN_DEVIATION_RESPONSIBLE === setting.key) {
          this.companySettings[srcDstKeys[setting.key]] = enabled || this.isManagerOrAbove();
        } else if (setting.key in srcDstKeys) {
          this.companySettings[srcDstKeys[setting.key]] = enabled;
        }
      });
    }

    if (this.user.company.company_strings && this.user.company.company_strings.length) {
      this.user.company.company_strings.forEach(string => {
        this.companyStrings[string.name] = string.value;
      });
    }
    this.companySettings.timeZone = this.user.company.timezone;
  }

  public getCurrentUserClones(): Promise<any> {
    return this.apiService.get('/users/clones')
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }

  /**
   * Get role of current user
   * @return {any}
   */
  public getCurrentUserRole() {
    if (!this.user || !this.user.role) {
      return null;
    }

    return this.user.role;
  }

  /**
   * Check if current user has role Manger or above
   * @return {boolean}
   */
  public isManagerOrAbove() {
    if (!this.user || !this.user.role) {
      return false;
    }

    return [
      this.helperService.protocol.roles.SUPER_ADMIN,
      this.helperService.protocol.roles.AGENT,
      this.helperService.protocol.roles.ADMIN,
      this.helperService.protocol.roles.MANAGER
    ].indexOf(this.user.role.hierarchy) >= 0;
  }

  /**
   * Check if current user has role Manger or above
   * @return {boolean}
   */
  public isAdmin(): boolean {
    if (!this.user || !this.user.role) {
      return false;
    }

    return [
      this.helperService.protocol.roles.SUPER_ADMIN,
      this.helperService.protocol.roles.AGENT,
      this.helperService.protocol.roles.ADMIN,
    ].indexOf(this.user.role.hierarchy) >= 0;
  }

  /**
   * Check if current user is public user
   * @return {boolean}
   */
  public isPublicUser(): boolean {
    if (!this.user || !this.user.role) {
      return false;
    }

    return [
      this.helperService.protocol.roles.PUBLIC_USER,
    ].indexOf(this.user.role.hierarchy) >= 0;
  }

  /**
   * Check if current user can manage rfid
   * @return {boolean}
   */
  public canManageRFID(): boolean {
    return this.user && this.enabledRFID() && this.user.can_manage_rfid;
  }

  /**
   * Check if RFID not disabled for current user
   * @return {boolean}
   */
   public enabledRFID(): boolean {
    return this.user && !this.user.rfid_disabled;
  }

  /**
   * Show "Access denied" generic alert
   * @return {Promise<any>}
   */
  public showAccessDeniedAlert(doLogout = true): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.helperService.showAlert({
        message: this.langService.t('errors.access-denied'),
        buttons: [
          {
            text: this.langService.t('general.ok'),
            handler: () => {
              if (doLogout) {
                this.logout()
                  .then(resolve)
                  .catch(reject);
              } else {
                resolve();
              }
            }
          }
        ]
      });
    });
  }

  /**
   * Check if user in offline mode or have unsynced data and show warning message
   */
  public async offlineLogoutCheck(): Promise<any> {
    const syncRequests = await this.offlineHandlerService.getRequestsToSync();
    const isOfflineModeActive = await this.offlineHandlerService.isOfflineEnable();
    let message = this.langService.t('general.confirm-message');

    if (syncRequests && syncRequests.length) {
      message = this.langService.t('actions.confirmLogoutReset');
    } else if (isOfflineModeActive) {
      message = this.langService.t('actions.confirmOfflineLogout');
    }
    return new Promise<any>((resolve) => {
      this.helperService.showConfirm(message).then(resolve);
    });
  }

  public async checkAccessToken() {
    return this.accessTokenService.checkAccessToken();
  }

  public async refreshToken(refreshToken: string) {
    if (await this.offlineHandlerService.isOfflineEnable()) {
      return;
    }

    return this.fetchOauthToken('refresh_token', {
      refresh_token: refreshToken
    }).catch((err) => {
      if (err && err.status === 401) {
        this.toastService.showAccessDeniedNotification();
        return this.logout().then(() => {
          throw err;
        });
      }
      return Promise.reject(err);
    });
  }

  private fetchOauthToken(grantType: string, data: any): Promise<any> {
    const postData = Object.assign({}, this.getOauthData(grantType), data);

    return this.apiService.post('/oauth/token', postData, {
      url: 'base',
      useAuth: false,
      disableErrorHandler: true,
      noRetry: true
    })
      .then((response: IOAuthResponse) => {
        this.handleOauthResponse(response);
        return Promise.resolve();
      })
      .catch(error => Promise.reject(error));
  }

  private getOauthData(grantType: string): IOAuthRequest {
    const environment = this.environmentService.getEnvironment();
    return {
      client_id: environment.auth.clientId,
      client_secret: environment.auth.clientSecret,
      scope: 'app',
      grant_type: grantType
    };
  }

  private async handleOauthResponse(response: IOAuthResponse) {
    await this.accessTokenService.setOauthToken(response);
  }

  public fetchCurrentUserAndGroupsUid() {
    const responsiblesList = [this.user.uid];
    return this.apiService.get('/users/me/groups', {}, {cache: true}).then(userGroups => {
      if (userGroups && userGroups.length) {
        userGroups.forEach(group => {
          responsiblesList.push(
              group.uid,
          );
        });
      }
      return responsiblesList;
    });
  }
}
