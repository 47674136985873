import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import {IWorktimeCategory} from '../interfaces/worktime-category.interface';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import {IWorktimesHistory, IWorktimesHistoryElement, IWorktimesHistoryTotalWorkTime} from '../interfaces/worktimes-history.interface';
import {LangService} from './lang.service';
import { HelperService } from './helper.service';
import { CategoryObjectType } from '../constants/category-object-type';
import { format } from 'date-fns';
import {WorktimesPeriod} from '../enums';

@Injectable({
  providedIn: 'root'
})
export class WorktimeService {

    /**
     * @param api Api service.
     * @param langService Translator
     * @param helperService
     */
    constructor(
        private api: ApiService,
        private readonly langService: LangService,
        private readonly helperService: HelperService,
    ) { }

    /** @const URL path patters. **/
    private readonly PATHS = {
        categories: '/worktimes/categories',
        userHistory: '/users/me/worktimes'
    };

    /**
    * Fetch all categories.
    */
    public getCategories(): Promise<IWorktimeCategory[]> {
        return this.api.get(this.PATHS.categories);
    }

    /**
    * Fetch all user worktime history.
    */
    public getUserWorktimesHistory(param: any): Promise<IWorktimeCategory[]> {
        return this.api.get(this.PATHS.userHistory, param);
    }

    /**
     * GroupedBy user worktime history by Resources.
     */
    public groupWorkTimeHistoryByResources(worktimesHistory: IWorktimeCategory[]): IWorktimeCategory[] {
        return groupBy(worktimesHistory, 'resource.id');
    }

    /**
     * GroupedBy user worktime history by Resources and Types.
     * Add also the total worktime for each ressources
     */
    public groupWorktimeHistoryByResourcesAndTypes(worktimesHistory: IWorktimeCategory[]): IWorktimesHistory {
        const groupedByResourceAndTypes: IWorktimesHistory = {
            IndependentDeviation: [],
            Check: [],
            Service: [],
            Deviation: []
        };

        groupBy(worktimesHistory , (subItems) => {
            if (subItems.length > 0) {
                const worktimesHistoryOrderByResource = {
                    resource: subItems[0].resource,
                    worktimes: subItems
                };
                let resourceTotalWorkTime = 0;
                subItems.forEach(subItem => {
                    resourceTotalWorkTime +=  Number(subItem.value);
                });
                subItems[0].resource.totalWorkTime = resourceTotalWorkTime;
                if (subItems[0].resource.type && groupedByResourceAndTypes[subItems[0].resource.type]) {
                    groupedByResourceAndTypes[subItems[0].resource.type].push(worktimesHistoryOrderByResource);
                }
            }
        });
        return groupedByResourceAndTypes;
    }


    /**
     * get worktime history group by Worktime Admin Types.
     * Add also the total worktime for each ressources
     */
    public getUserWorktimesListGroupByAdminTypes(worktimesUserHistory: IWorktimeCategory[]): Array<IWorktimesHistoryElement> {
        const worktimesListGroupByAdminTypes: IWorktimesHistoryElement[] = [];
        worktimesUserHistory = groupBy(worktimesUserHistory, 'type_category.id');
        map(worktimesUserHistory, (value, key) => {
            // After groupBy, if  type_category is null the key is setup to undefined
            if (key !== 'undefined') {
                const worktimesHistoryElement: IWorktimesHistoryElement = {
                    typeName: value[0].type_category.name,
                    typeId: key,
                    totalWorkTime: null,
                    worktimesHistory: this.groupWorktimeHistoryByResourcesAndTypes(this.groupWorkTimeHistoryByResources(value)),
                };
                worktimesHistoryElement.totalWorkTime = this.countTotalWorktime(worktimesHistoryElement.worktimesHistory);
                worktimesListGroupByAdminTypes.push(worktimesHistoryElement);
            }
        });
        return worktimesListGroupByAdminTypes;
    }

    /**
     * Count the total worktime for a Type
     */
    public countTotalTypeWorktime(TypeWorktimesHistory): number {
        let totalTypeWorktime = 0;
        TypeWorktimesHistory.forEach(item => {
            item.worktimes.forEach(subItem => {
              totalTypeWorktime +=  Number(subItem.value);
            });
        });
        return totalTypeWorktime;
    }

    /**
     * Count the total worktime for each Types and the full total
     */
    public countTotalWorktime(worktimesHistory): IWorktimesHistoryTotalWorkTime {
        const totalWorkTime = {
            checks: this.countTotalTypeWorktime(worktimesHistory.Check),
            services: this.countTotalTypeWorktime(worktimesHistory.Service),
            deviations: this.countTotalTypeWorktime(worktimesHistory.Deviation) + this.countTotalTypeWorktime(worktimesHistory.IndependentDeviation),
            total: 0,
        };
        totalWorkTime.total = totalWorkTime.checks + totalWorkTime.deviations + totalWorkTime.services;
        return totalWorkTime;
    }

    /**
     * Init All types worktimes History item
     */

    public initAllTypesWorktimesHistoryElement(worktimesHistory: IWorktimeCategory[]): IWorktimesHistoryElement {
        const allTypesWorktimesHistory: IWorktimesHistoryElement = {
            typeName: this.langService.t('worktime.allTypes'),
            typeId: null,
            totalWorkTime: null,
            worktimesHistory: null,
        };
        allTypesWorktimesHistory.worktimesHistory = this.groupWorktimeHistoryByResourcesAndTypes(this.groupWorkTimeHistoryByResources(worktimesHistory));
        allTypesWorktimesHistory.totalWorkTime = this.countTotalWorktime(allTypesWorktimesHistory.worktimesHistory);
        return allTypesWorktimesHistory;
    }

    /**
     * Init worktimes History List that need to be display on the screen
     */

    public initWorktimesHistoryList(worktimesHistory: IWorktimeCategory[]): Array<IWorktimesHistoryElement> {
        const worktimesHistoryList: IWorktimesHistoryElement[] = [];

        // Create the "All Types" item and add it into worktimesHistoryList
        worktimesHistoryList.push(this.initAllTypesWorktimesHistoryElement(worktimesHistory));

        // Add all worktimes grouped by admin types setup in company settings.
        this.getUserWorktimesListGroupByAdminTypes(worktimesHistory).map(value => {
            worktimesHistoryList.push(value);
        });
        return worktimesHistoryList;
    }

    /**
     * Init worktimes History List that need to be display on the screen
     */

    public sortWorktimesList(worktimes, selectedPeriod) {
        // periodDeterminant will be used to determine if a certain date falls outside selected period
        // When used in date-fns format method it will return current week or month of date and
        // this is checked against the corresponding number for start of period date
        let periodDeterminant, firstOfPeriod;
        const today = new Date();
        switch (selectedPeriod) {
            case WorktimesPeriod.THIS_MONTH:
                periodDeterminant = 'M';
                firstOfPeriod = new Date(today.getFullYear(), today.getMonth(), 1);
                break;
            case WorktimesPeriod.PREV_MONTH:
                periodDeterminant = 'M';
                firstOfPeriod = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                break;
            case WorktimesPeriod.THIS_WEEK:
                periodDeterminant = 'I';
                firstOfPeriod = new Date(today.setDate(today.getDate() - today.getDay() + 1));
                break;
            case WorktimesPeriod.PREV_WEEK:
                periodDeterminant = 'I';
                firstOfPeriod = new Date(today.setDate(today.getDate() - today.getDay() - 6));
                break;
        }

        const groupedWorktimes = {
            worktimes: {},
            totalWt: {},
            labels: {},
        };

        const firstOfPeriodIdx = format(firstOfPeriod, periodDeterminant);
        let weekNum, dayNum;
        const itteratingDate = firstOfPeriod;
        while (itteratingDate) {
            weekNum = format(itteratingDate, 'I');
            dayNum = format(itteratingDate, 'i');

            // Itterating date falls outside selected period
            if (format(itteratingDate, periodDeterminant) !== firstOfPeriodIdx) {
                break;
            }

            // Prepare worktime object for a new week
            if (!groupedWorktimes['worktimes'][weekNum]) {
                let lastDayOfWeek = new Date(itteratingDate);
                lastDayOfWeek = new Date(lastDayOfWeek.setDate(itteratingDate.getDate() + (7 - itteratingDate.getDay())))
                // Check if last day of week is in new month
                if (format(itteratingDate, 'M') !== format(lastDayOfWeek, 'M')) {
                    lastDayOfWeek = new Date(itteratingDate.getFullYear(), itteratingDate.getMonth() + 1, 0);
                }
                groupedWorktimes['worktimes'][weekNum] = {};
                groupedWorktimes['labels'][weekNum] = {
                    weekLabel: `${format(itteratingDate, 'dd')}-${format(lastDayOfWeek, 'dd LLL')}` ,
                    days: {},
                };
                groupedWorktimes['totalWt'][weekNum] = {
                    weekTotal: 0,
                    days: {},
                };
            }
            // Prepare worktime object for a new day
            if (!groupedWorktimes['worktimes'][weekNum][dayNum]) {
                groupedWorktimes['worktimes'][weekNum][dayNum] = [];
                groupedWorktimes['labels'][weekNum]['days'][dayNum] = format(itteratingDate, 'EEE d LLL');
                groupedWorktimes['totalWt'][weekNum]['days'][dayNum] = 0;
            }

            itteratingDate.setDate(itteratingDate.getDate() + 1);

        }

        worktimes.forEach(wt => {
            const weekNum = format(new Date(wt.work_date_end), 'I');
            const dayNum = format(new Date(wt.work_date_end), 'i');
            groupedWorktimes['totalWt'][weekNum]['weekTotal'] += parseFloat(wt.value);
            groupedWorktimes['totalWt'][weekNum]['days'][dayNum] += parseFloat(wt.value);
            groupedWorktimes['worktimes'][weekNum][dayNum].push(wt);
        });
        return groupedWorktimes;
    }

    /** Get type categories */
    public getTypeCategories(objectType: CategoryObjectType): IWorktimeCategory[] {
        return this.helperService
          .getWorktimesCategories()
          .filter(
            category =>
              category[objectType] === true
              && category.type === this.helperService.protocol.worktimeCategoryTypes.TYPE
          );
    }

    /** Get activity categories */
    public getActivityCategories(objectType: CategoryObjectType): IWorktimeCategory[] {
        return this.helperService
          .getWorktimesCategories()
          .filter(
            category =>
              category[objectType] === true
              && category.type === this.helperService.protocol.worktimeCategoryTypes.ACTIVITY
          );
    }
}
