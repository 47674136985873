import { Pipe, PipeTransform } from '@angular/core';
import { ResponsiblesClient } from '../../../services/clients/cp-api/responsibles/responsibles.client';
import { LangService } from '../../../services/lang.service';

@Pipe({
  name: 'externalNameByUid'
})
export class ExternalNameByUidPipe implements PipeTransform {
  public constructor(
    private readonly responsiblesClt: ResponsiblesClient,
    private readonly langSrv: LangService
  ) {
  }

  /** Get name of external user by UID */
  public async transform(uid: string): Promise<string> {
    return this.responsiblesClt
      .getExternal(uid)
      .then(external => external?.name || this.langSrv.t('externals.removedExternal'));
  }
}
