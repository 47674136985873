import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { ModalService } from '../services/modal.service';
import { SharedModule } from '../components/shared.module';
import { ObjectSelectorComponent } from './object-selector/object-selector.component';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { DialogComponent } from './dialog/dialog.component';
import { ImageEditorComponent } from './image-editor/image-editor.component';
import { PlaceableSelectorComponent } from './placeable-selector/placeable-selector.component';
import {RadioCheckListSelectorComponent} from './radio-check-list-selector/radio-check-list-selector.component';
import {DatetimeComponent} from './datetime/datetime.component';
import { OdometerConfirmationComponent } from './odometer-confirmation/odometer-confirmation.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    SharedPipesModule,
    FormsModule,
  ],
  declarations: [
    ObjectSelectorComponent,
    DialogComponent,
    ImageEditorComponent,
    PlaceableSelectorComponent,
    RadioCheckListSelectorComponent,
    DatetimeComponent,
    OdometerConfirmationComponent
  ],
  exports: [
    ObjectSelectorComponent,
    DialogComponent,
    ImageEditorComponent,
    PlaceableSelectorComponent,
    RadioCheckListSelectorComponent,
    DatetimeComponent,
    OdometerConfirmationComponent
  ],
  providers: [ModalService],
})
export class SharedModalsModule {}
