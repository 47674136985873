import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, takeUntil, timeout } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { Platform } from '@ionic/angular';
import cloneDeep from 'lodash/cloneDeep';
import retry from 'async/retry';

import { IApiOptions } from '../interfaces';
import { PromiseQueueUtil } from '../utils/promise-queue.util';
import { OfflineHandlerService } from './offline/handler.service';
import { EnvironmentService } from './environment.service';
import { ToastService } from './toast.service';
import { EventsService } from './events.service';
import { AppUpdaterService } from './app-updater.service';
import { LangService } from './lang.service';
import { FileService } from './file.service';
import { badNetworkErrorCodes, unauthorizedError } from '../constants/error-codes';
import { AccessTokenService } from './access-token.service';
import { OfflineRouterService } from './offline/router.service';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  private static readonly ATTEMPTS_MAX = 3; // how many attempts for one http request
  private static readonly ATTEMPTS_INTERVAL = [500, 5000]; // 500ms for first retry, 5s for second retry
  protected statusPath = '/api-status.php';

  /**
   * API url specified in environment(.prod).ts
   */
  protected API_URL = '';
  protected BASE_URL = '';
  private promiseQueue;

  /**
   * Default headers
   */
  protected httpOptions = {
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'Accept': 'application/json',
      'App-Version': '1.0',
    }
  };

  private pendingGetRequests = <any>{};
  private cancelPendingRequests: Subject<void> = new Subject();

  private requestsCache = <any>{};
  private defaultCacheTTL = 24 * 60 * 60; // 24 hours

  constructor(
    private http: HttpClient,
    private httpNative: HTTP,
    private offlineHandler: OfflineHandlerService,
    private platform: Platform,
    private events: EventsService,
    protected environmentService: EnvironmentService,
    private toastService: ToastService,
    private appUpdaterService: AppUpdaterService,
    private langService: LangService,
    private fileService: FileService,
    private accessTokenService: AccessTokenService,
    private offlineRouteSrv: OfflineRouterService,
  ) {
    this.promiseQueue = new PromiseQueueUtil(10);
    if (this.platform.is('capacitor')) {
      this.httpNative.setDataSerializer('json');
      this.httpNative.setRequestTimeout(1800);
    }

    this.events.subscribe('env:changeRegion', this.onEnvChangeRegion.bind(this));
  }

  /**
   * Initialize service based on environment
   */
  public initService() {
    const environment = this.environmentService.getEnvironment();
    this.API_URL = environment.apiUrl;
    this.BASE_URL = environment.baseUrl;
    this.httpOptions.headers['App-Version'] = environment.appVersion;
  }

  /**
   * Cancel all pending requests
   */
  public cancelRequests(): void {
    this.cancelPendingRequests.next();
    this.promiseQueue.clear();
  }

  /**
   * Send GET request to API
   * @param {string} url Endpoint that should be called
   * @param data Object with request parameters
   * @param {IApiOptions} options Addition options for API call
   * @return {Promise<any>}
   */
  public async get(url: string, data?: any, options?: IApiOptions): Promise<any> {
    const urlKey = this.getRequestCacheKey(url, data);
    if (this.pendingGetRequests.hasOwnProperty(urlKey)) {
      return this.pendingGetRequests[urlKey];
    }

    const promise = this.makeRequest('get', url, data, options);
    this.pendingGetRequests[urlKey] = promise;

    return promise.finally(() => {
      delete this.pendingGetRequests[urlKey];
    });
  }

  /**
   * Send POST request to API
   * @param {string} url Endpoint that should be called
   * @param data Object with request parameters
   * @param {IApiOptions} options Addition options for API call
   * @return {Promise<any>}
   */
  public async post(url: string, data?: any, options?: IApiOptions): Promise<any> {
    return this.makeRequest('post', url, data, options);
  }

  /**
   * Send PUT request to API
   * @param {string} url Endpoint that should be called
   * @param data Object with request parameters
   * @param {IApiOptions} options Addition options for API call
   * @return {Promise<any>}
   */
  public async put(url: string, data, options?: IApiOptions): Promise<any> {
    return this.makeRequest('put', url, data, options);
  }

  /**
   * Send PATCH request to API
   *
   * @param url Endpoint
   * @param data Request parameters
   * @param options Addition request options
   * @return {Promise}
   */
  public async patch(url: string, data = {}, options?: IApiOptions): Promise<any> {
    return this.makeRequest('patch', url, data, options);
  }

  /**
   * Send DELETE request to API
   * @param {string} url Endpoint that should be called
   * @param {IApiOptions} options Addition options for API call
   * @return {Promise<any>}
   */
  public async delete(url: string, options?: IApiOptions): Promise<any> {
    return this.makeRequest('delete', url, {}, options);
  }

  /**
   * Send request to api to get server status
   * @return {Promise<any>}
   */
  public async checkApiStatus() {
    const isOffline = await this.offlineHandler.isOfflineEnable();
    if (isOffline) {
      return Promise.resolve({
        status: 200
      });
    }

    const url = this.statusPath;

    if (this.platform.is('capacitor')) {
      return this.httpNative.get(this.BASE_URL + url, {}, {})
        .then(this.httpNativeResponseHandler);
    } else {
      const headers = new HttpHeaders({});
      return this.http
        .get(this.BASE_URL + url, {
          headers: headers
        })
        .pipe(
          timeout(15000),
          catchError(() => {
            return of(null);
          })
        )
        .toPromise();
    }
  }

  /**
   * Get http options object
   * @param {IApiOptions} options
   * @return {Promise<any>}
   */
  private getHttpOptions(options: IApiOptions): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = this.httpOptions.headers;

      const httpOptions = {
        headers: headers,
        url: this.API_URL,
        useAuth: true
      };

      if (typeof options === 'undefined') {
        options = {};
      }

      if ('url' in options) {
        if (options.url.length > 0) {
          switch (options.url) {
            case 'base':
              httpOptions.url = this.BASE_URL;
              break;

            default:
              // Defaults to API_URL
              break;
          }
        }
      }
      if (options && options.multipart) {
        delete httpOptions.headers['Content-Type'];
      }

      this.getAuthHeader(options).then(() => {
        httpOptions.headers = this.getHeaders(options);
        resolve(httpOptions);
      }).catch(reject);
    });
  }

  /**
   * Get authorization headers based on options
   * @param {IApiOptions} options
   * @return {Promise<any>}
   */
  private getAuthHeader(options: IApiOptions): Promise<any> {
    return new Promise((resolve, reject) => {
      if (options.useAuth !== false || typeof options.useAuth === 'undefined') {
        this.accessTokenService
          .getAccessToken()
          .then(token => {
            this.httpOptions.headers['Authorization'] = 'Bearer ' + token;
            resolve(this.httpOptions.headers);
          })
          .catch(() => {
            reject(new Error('Failed to get access token'));
          });
      } else {
        resolve(this.httpOptions.headers);
      }
    });
  }

  /**
   * Check if there is extra headers specified in options.
   * And in case there is, modifying default headers
   * @param options
   */
  private getHeaders(options: IApiOptions): any {
    if (typeof options === 'undefined') {
      return this.httpOptions.headers;
    }

    if (!('headers' in options)) {
      // key 'headers' does not exist in options, return default headers
      return this.httpOptions.headers;
    }

    const headers = options.headers;
    if (Object.keys(headers).length === 0 && headers.constructor === Object) {
      // headers object is empty, return default headers
      return this.httpOptions.headers;
    }

    const resultHeaders = cloneDeep(this.httpOptions.headers);
    // loop through additional headers and add them to existing headers object
    // eslint-disable-next-line guard-for-in
    for (const option in headers) {
      resultHeaders[option] = headers[option];
    }

    return resultHeaders;
  }

  /**
   * Make an http request (offline or online)
   * @param {string} method
   * @param {string} url
   * @param data
   * @param options
   * @return {Promise<any>}
   */
  private async makeRequest(method: string, url: string, data: any, options: any): Promise<any> {
    const forceOnline = options && options.forceOnline;
    const isOffline = await this.offlineHandler.isOfflineEnable();
    if (isOffline && !forceOnline) {
      if (method === 'get' || this.offlineRouteSrv.getRoute(url)?.isFetching) { // That's fetching request
        return this.offlineHandler.getResponse(url, data);
      } else {
        return this.saveRequest(method, url, data, options);
      }
    }

    // try to get response from cache
    let cacheKey = null;
    if (method === 'get') {
      if (options && (options.cache || options.createCache)) {
        cacheKey = this.getRequestCacheKey(url, data);
      }

      if (options && options.cache) {
        const cache = this.getRequestCache(cacheKey);
        if (cache !== null) {
          return Promise.resolve(cache);
        }
      }
    } else {
      this.clearAllRequestCache();
    }

    if (!this.platform.is('capacitor')) {
      return this.sendRequest(method, url, data, options)
        .then((responseData) => {
          this.setRequestCache(cacheKey, responseData, options);
          return responseData;
        });
    }

    return new Promise((resolve, reject) => {
      this.promiseQueue.pushPromise(() => {
        return this.sendRequest(method, url, data, options)
          .then((responseData) => {
            this.setRequestCache(cacheKey, responseData, options);
            resolve(responseData);
          })
          .catch(err => {
            reject(err);
          });
      });
    });
  }

  /**
   * Send http request and handle errors
   * @param {string} method
   * @param {string} url
   * @param data
   * @param {IApiOptions} options
   * @return {Promise<any>}
   */
  private async sendRequest(method: string, url: string, data: any, options: any): Promise<any> {
    return retry(
      {
        times: options?.noRetry ? 1 : ApiService.ATTEMPTS_MAX,
        interval: (retryCount) => {
          return  ApiService.ATTEMPTS_INTERVAL[retryCount - 1] || ApiService.ATTEMPTS_INTERVAL[0];
        },
        errorFilter: (err) => {
          if (!err || !err.hasOwnProperty('status')) {
            return false;
          }

          // retry if request failed due to 401 error
          if (err.status === unauthorizedError) {
            return true;
          }
          // retry only if GET request return bad network error
          return method === 'get' && this.isBadNetworkErrorCode(err.status);
        }
      },
      callback => {
        return this.makeHttpCall(method, url, data, options)
          .then(res => {
            callback(null, res);
          })
          .catch(err => {
            callback(err);
          });
      }
    ).catch((err) => {
      this.handleRequestError(err, options);
      throw err;
    });
  }

  /**
   * Make http call based on current platform
   * @param {string} method
   * @param {string} url
   * @param data
   * @param options
   * @return {Promise<any>}
   * @private
   */
  private async makeHttpCall(method: string, url: string, data: any, options: any): Promise<any> {
    const isDeviceOnline = await this.offlineHandler.isDeviceOnline();
    if (!isDeviceOnline) {
      throw new HttpErrorResponse({
        error: 'Device offline',
        status: 2
      });
    }

    const httpOptions = await this.getHttpOptions(options);

    if (this.platform.is('capacitor')) {
      if (method === 'get' && data && typeof data === 'object') {
        Object.keys(data).forEach(key => {
          if (Array.isArray(data[key])) {
            data[key] = data[key].join(',');
          } else if (typeof data[key] === 'number') {
            data[key] = data[key].toString();
          } else if (data[key] && typeof data[key] !== 'string' && data[key].toString) {
            data[key] = data[key].toString();
          } else if (!data[key]) {
            data[key] = '';
          }
        });
      }
      if (['post', 'put', 'patch'].indexOf(method) !== -1 && !data) {
        data = {};  // advanced-http: "data" option is configured to support only following data types: Array, Object
      }

      return new Promise<any>((resolve, reject) => {
        if (['get', 'post', 'put', 'delete', 'patch'].indexOf(method) !== -1) {
          if (options && options.upload) {
            delete httpOptions.headers['Content-Type'];

            const filePaths = [];
            const fileNames = [];
            const fileData = cloneDeep(data);
            options.upload.forEach(prop => {
              if (fileData.hasOwnProperty(prop)) {
                let filePath = fileData[prop];
                if (filePath.indexOf('?') > 0) {
                  filePath = filePath.split('?')[0]; // remove query parameters because its not supported
                }

                filePaths.push(filePath);
                fileNames.push(prop);
                delete fileData[prop];
              }
            });

            this.httpNative.uploadFile(httpOptions.url + url, fileData, httpOptions.headers, filePaths, fileNames)
              .then(this.httpNativeResponseHandler.bind(this))
              .then(res => {
                this.fileService.deleteFiles(filePaths);
                return res;
              })
              .then(res => resolve(res))
              .catch(err => {
                return reject(err);
              });

          } else {
            this.httpNative[method](httpOptions.url + url, data, httpOptions.headers)
              .then(this.httpNativeResponseHandler.bind(this))
              .then(resolve)
              .catch(reject);
          }
        } else {
          return reject('Not supported method');
        }
      });
    } else {
      const headers = new HttpHeaders(httpOptions.headers);
      const fullUrl = httpOptions.url + url;

      if (method === 'get') {
        if (data && typeof data === 'object') {
          Object.keys(data).forEach(key => {
            if (Array.isArray(data[key])) {
              data[key] = data[key].join(',');
            }
          });
        }

        return this.http
          .get(httpOptions.url + url, {
            headers: headers,
            params: data
          })
          .pipe(takeUntil(this.cancelPendingRequests))
          .toPromise();
      } else if (method === 'post') {
        return this.http
          .post(httpOptions.url + url, data, {
            headers: headers
          })
          .toPromise();
      } else if (method === 'put') {
        return this.http
          .put(httpOptions.url + url, data, {
            headers: headers
          })
          .toPromise();
      } else if (method === 'delete') {
        return this.http
          .delete(httpOptions.url + url, {
            headers: headers
          })
          .toPromise();
      } else if (method === 'patch') {
        return this.http
          .patch(fullUrl, data, {
            headers
          })
          .toPromise();
      }
    }
  }

  /**
   * Parse response from http native call
   * @param response
   * @return {any}
   */
  private httpNativeResponseHandler(response: any): any {
    if (!response || !response.data) {
      return null;
    }

    if (response.headers && response.headers['app-latest-version'] && response.headers['app-minimum-version']) {
      const version = response.headers['app-latest-version'];
      const minimumVersion = response.headers['app-minimum-version'];

      if (version) {

        this.appUpdaterService.checkVersion(version, minimumVersion);
      }
    }

    try {
      return JSON.parse(response.data);
    } catch (e) {
      return null;
    }
  }

  /**
   * Handle error http response
   * @param err
   * @param httpOptions
   */
  private handleRequestError(err, httpOptions: any): void {
    if (!err || !err.hasOwnProperty('status') || (httpOptions && httpOptions.disableErrorHandler)) {
      return;
    }

    this.captureExceptionAndSendToSentryAsString(err);
    this.showErrorByHttpCode(err.status);

    if (err.status === unauthorizedError) {
      this.events.publish('api:unauthenticated');
    }
  }

  /**
   * Show error messages based on http code
   * @param code
   */
  public showErrorByHttpCode(code) {
    this.toastService.showToast({
      message: this.getErrorMessageByHttpCode(code),
      color: 'danger'
    });
  }

  /**
   * Get error message based on http code
   * @param code
   */
  public getErrorMessageByHttpCode(code) {
    let message = '';
    if (this.isBadNetworkErrorCode(code)) {
      message = this.langService.t('errors.network-connection');
    } else if (code === unauthorizedError || code === 403) {
      message = this.langService.t('errors.access-denied');
    } else if (code === 404) {
      message = this.langService.t('errors.not-found');
    } else if (code === 500) {
      message = this.langService.t('errors.generic-notification');
    } else {
      message = this.langService.t('errors.generic-notification');
    }

    return message;
  }

  /**
   * Check error code - is it a network error
   * @param code
   */
  public isBadNetworkErrorCode(code) {
    return badNetworkErrorCodes.indexOf(code) >= 0;
  }

  /**
   * Triggered when environment region is changed
   */
  private onEnvChangeRegion() {
    this.initService();
  }

  /**
   * Clear all cached requests
   */
  private clearAllRequestCache(): void {
    this.requestsCache = {};
  }

  /**
   * Get request response from cache
   * @param {string} key
   * @return {any}
   */
  private getRequestCache(key: string): any {
    if (!key) {
      return null;
    }

    const cache = this.requestsCache[key];
    if (!cache || !cache.hasOwnProperty('data')) {
      return null;
    }

    if (cache.expiresAt && cache.expiresAt < (new Date())) {
      return null;
    }

    return cloneDeep(cache.data);
  }

  /**
   * Save request response to cache
   * @param {string} key
   * @param data
   * @param {IApiOptions} options
   */
  private setRequestCache(key: string, data: any, options: IApiOptions): void {
    if (!options || !(options.cache || options.createCache) || !key) {
      return;
    }

    const expiresAt = new Date();
    const cacheTTL = options.cacheTTL || this.defaultCacheTTL;
    expiresAt.setSeconds(expiresAt.getSeconds() + cacheTTL);

    this.requestsCache[key] = {
      data: cloneDeep(data),
      expiresAt: expiresAt
    };
  }

  /**
   * Get key for request cache based on url and parameters
   * @param {string} url
   * @param params
   * @return {string}
   */
  private getRequestCacheKey(url: string, params: any): string {
    let key = url;
    if (params) {
      key += '-' + JSON.stringify(params);
    }
    return key;
  }

  /** Save changing request */
  protected saveRequest(method: string, url: string, data?: any, options?: any): Promise<any> {
    return this.offlineHandler.saveRequest(method, url, data, options);
  }


  /** Capture Exception and send it to sentry as a string to get a better understand of the problem */
  private captureExceptionAndSendToSentryAsString(err: HttpErrorResponse): void {
    const capturedException = err.status + ' ' +  err?.error?.message;
    console.error('handleRequestError() capturedException', capturedException);
  }
}
