import { Component, OnInit } from '@angular/core';
import Keyboard from 'simple-keyboard';
import { NumericKeyboardService } from '../../services/keyboard/numeric-keyboard.service';
import {LangService} from '../../services/lang.service';
import { NumericInputService } from '../../services/keyboard/numeric-input.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-numeric-keyboard',
  templateUrl: './numeric-keyboard.component.html',
  styleUrls: [
    './numeric-keyboard.component.scss',
    '../../../../node_modules/simple-keyboard/build/css/index.css'
  ],
})
export class NumericKeyboardComponent implements OnInit {
  private keyboard: Keyboard;
  public isOpen = false;
  private _openSubscription: Subscription;
  public text: string;

  constructor(
    private numericKeyboardService: NumericKeyboardService,
    private langService: LangService,
    private numericInputService: NumericInputService
  ) { }

  ngOnInit(): void {
    this._openSubscription = this.numericKeyboardService.onOpen().subscribe({
      next: (keyboard) => {
          this.createKeyboard(keyboard.value, keyboard.decimals);
    },
    });
  }
  /**
  *  Update input if a virtual keyboard is used
  */
  private onKNumericKeyChange(input: string, decimals: number): void {
    if (!this.numericInputService.isValidNumericInput(input, decimals)
    ) {
      this.keyboard.setInput(input.substring(0, input.length - 1));
      return;
    }
    this.numericKeyboardService.changeValue(input);
  }
  public destroyKeyboard() {
      this.keyboard.destroy();
      this.numericKeyboardService.close();
      // trick double clicking from keyboard when we press and hold the {escape}-button
      setTimeout( () =>  this.isOpen = false, 100);
  }

  public createKeyboard(value: string, decimals: number){
    const commaKey = decimals > 0 ? ',' : '';
      this.keyboard = new Keyboard({
        onChange: input => this.onKNumericKeyChange(input, decimals),
        onKeyReleased: button => button === '{escape}' ? this.destroyKeyboard() : null,
        layout: { default: ['- {escape}', '1 2 3', '4 5 6', '7 8 9', `${commaKey} 0 {bksp}`] },
        theme: 'hg-theme-default hg-layout-numeric numeric-theme',
        display: {
          '{bksp}': `${'<i class="fa-regular fa-backspace"></i>'}`,
          '{escape}': `${this.langService.t('actions.done')}`,
          '-': `${'<i class="fa-regular fa-minus"></i>'}`,
        },
        preventMouseDownDefault: true,
      });
      this.isOpen = true;
      this.keyboard.setInput(value ?? null);
  }
}
