import { Component, Input, Output, EventEmitter, forwardRef, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { SelectWorkplaceSectionComponent } from '../select-workplace-section/select-workplace-section.component';

@Component({
  selector: 'app-select-placeable-object',
  templateUrl: './select-placeable-object.component.html',
  styleUrls: ['./select-placeable-object.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectPlaceableObjectComponent),
      multi: true,
    },
  ],
})
export class SelectPlaceableObjectComponent implements OnChanges, ControlValueAccessor {
  public selectedPlace;
  public selectedObject = null;
  public selectedCheckVariant = null;
  public isDisabled = false;
  public selectType = 'object';
  public availableStatusBar = false;
  public showStatusBar = false;

  @Input() multiple = false;
  @Input() unitsOnly = false;
  @Input() roundsOnly = false;
  @Input() writableOnly = false;
  @Input() company = null;
  @Input() rfidTag = null;
  @Input() isPerformCheck = false;
  @Input() isRequired = false;
  @Input() allwaysDisplayUnitRoundList = true;
  @Input() withSelectAllButton = false;
  @Input() restrictNumberOfSelectedSite = null;
  @Input() preset = { workplaces: [], sections: [], objects: [], allObjectsChecked: false, allSectionsChecked: false, withEmptySection: false };
  @Input() withEmptySection = false;
  @Input() withFavorite = true;
  @Input() forceRefresh = false;
  @Input() objectInUse = 'all';

  @Output() objectChange = new EventEmitter();
  @Output() placeChange = new EventEmitter();
  @Output() allObjectsCheckedChange = new EventEmitter();
  @Output() allSectionsCheckedChange = new EventEmitter();
  @Output() presetChange = new EventEmitter();

  @ViewChild('workplaceSectionComponent', { static: false }) workplaceSectionComponent: SelectWorkplaceSectionComponent;

  constructor(private authService: AuthService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.preset && changes.preset.currentValue) {
      if (changes.preset.previousValue === undefined) {
        this.preset = changes.preset.currentValue;
        this.withEmptySection = changes.preset.currentValue.withEmptySection;
      }
    }
    if (changes.forceRefresh && changes.forceRefresh.currentValue) {
      this.forceRefresh = changes.forceRefresh.currentValue;
    }
  }

  get selectedPlaceableObject() {
    return {
      place: this.selectedPlace,
      object: this.selectedObject,
      checkVariant: this.selectedCheckVariant,
    };
  }

  /**
   * triggers when selected object is changed
   */
  public onObjectChange() {
    if (!this.multiple && this.selectedObject) {
      let workplaceId = null;
      if ((!this.selectedPlace || !this.selectedPlace.workplace) && this.selectedObject.workplace_id) {
        workplaceId = this.selectedObject.workplace_id;
      }

      let sectionId = null;
      if ((!this.selectedPlace || !this.selectedPlace.section) && this.selectedObject.section && this.selectedObject.section.length) {
        sectionId = this.selectedObject.section[0].id;
      }

      this.workplaceSectionComponent.selectWorkplaceById(workplaceId).then(() => this.workplaceSectionComponent.selectSectionById(sectionId));
    }
    this.objectChange.emit(this.selectedObject);

    this._onChange(this.selectedPlaceableObject);
  }

  public onPlaceChange() {
    // If there is already a unit selected and the user change to a section where the unit is not link to
    // we need to update the parent component to disable the user to go next
    // TODO RENEW OLD CODE
    //  this.selectedPlace?.section.id is impossible => alway an array
    if (this.selectedPlace && this.selectedPlace?.section && this.selectedObject) {
      if (this.selectedObject.section && !this.selectedObject.section.find(section => section.id === this.selectedPlace?.section.id)) {
        this.selectedObject = null;
        this.objectChange.emit(this.selectedObject);
      }
    }

    this.placeChange.emit(this.selectedPlace);
    this._onChange(this.selectedPlaceableObject);

    this.availableStatusBar = !!(this.authService.companySettings.showCheckStatusbarObjectSelector && this.selectedPlace && this.selectedPlace.workplace);
  }

  public onCheckVariantChange() {
    if (this.selectedCheckVariant && this.selectedCheckVariant.object) {
      this.selectedObject = this.selectedCheckVariant.object;
    } else {
      this.selectedObject = null;
    }

    this.onObjectChange();
  }

  writeValue(obj: any): void {
    if (obj) {
      if (obj.place) {
        this.selectedPlace = obj.place;
      }
      if (obj.object) {
        this.selectedObject = obj.object;
      }
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /**
   * Call if input was "touched"
   * @private
   */
  private _onTouched = () => {};

  /**
   * Call if value was changed inside our component
   * @param _
   * @private
   */
  private _onChange = (_: any) => {};

  /** trigger event when All Sections checked **/
  public changeAllSections(event) {
    this.allSectionsCheckedChange.emit(event);
  }

  /** trigger event when All Units / Rounds checked **/
  public changeAllObjectsChecked(event) {
    this.allObjectsCheckedChange.emit(event);
  }

  /** Update Object list when withEmptySection is false and no selected places */
  public onEmptySectionChange(event) {
    this.withEmptySection = event;
    this.preset.withEmptySection = event;
    this.presetChange.emit(this.preset);
    if (!this.withEmptySection && (this.selectedPlace.section === null || this.selectedPlace.section?.length === 0)) {
      this.selectedObject = [];
      this.onObjectChange();
    }
  }

  public displayUnitRoundComponent(): boolean {
    const isObject = this.selectType === 'object';
    const hasSection = this.selectedPlace?.section?.length > 0;
    const hasWorkplace = this.selectedPlace?.workplace?.length > 0;

    // Home and Home > My tasks > filter : site & unit always displayed
    // Offline > Only if you selected at least a site, section appears, then to make appear unit & round, you need to click on one section or all checkbox or on sections.with-empty-section
    const shouldDisplayUnitRoundList = this.allwaysDisplayUnitRoundList || hasSection || (hasWorkplace && this.withEmptySection);

    return isObject && shouldDisplayUnitRoundList;
  }
}
