import {format, formatDistanceToNow, parseISO} from 'date-fns';
import UserInfo from '../constants/user-info';
import {
  sv,
  de,
  es,
  nb,
  pt,
  nl,
  enGB,
  enUS,
  pl,
  da,
  fr,
  cs,
  lv,
  ru,
  ro,
  it,
  fi,
  zhCN,
  he,
  lt,
 } from 'date-fns/locale';

const locales = {
  'da': da,
  'nl': nl,
  'en-oem': enGB,
  'en-oem-1': enGB,
  'en-sempertrans': enGB,
  'en': enGB,
  'de-hseq': de,
  'de': de,
  'no': nb,
  'pl': pl,
  'pt': pt,
  'es': es,
  'sv-byggprojekt': sv,
  'sv-fastighet': sv,
  'sv-fordon': sv,
  'sv-friskis': sv,
  'sv-larm': sv,
  'sv-marin': sv,
  'sv-oem': sv,
  'sv-rederi-1': sv,
  'sv-rederi-2': sv,
  'sv-retail': sv,
  'sv-secondary': sv,
  'sv-third': sv,
  'sv-fourth': sv,
  'sv-fifth': sv,
  'sv-sixth': sv,
  'sv-seventh': sv,
  'sv-eighth': sv,
  'sv-ninth': sv,
  'sv-tenth': sv,
  'sv-eleventh': sv,
  'sv-twelfth': sv,
  'sv-thirteenth': sv,
  'sv-fourteenth': sv,
  'sv-fifteenth': sv,
  'sv-sixteenth': sv,
  'sv-seventeenth': sv,
  'sv-vaktbolag': sv,
  'sv-hseq': sv,
  'sv': sv,
  'cs': cs,
  'en-secondary': enGB,
  'en-third': enGB,
  'en-fourth': enGB,
  'en-fifth': enGB,
  'en-sixth': enGB,
  'en-seventh': enGB,
  'en-eight': enUS,
  'en-ninth': enGB,
  'en-tenth': enGB,
  'en-eleventh': enGB,
  'en-twelfth': enGB,
  'en-internal': enGB,
  'en-internal-financial': enGB,
  'en-restaurant': enGB,
  'en-hseq': enGB,
  'fr': fr,
  'fr-secondary': fr,
  'lv': lv,
  'ru': ru,
  'ro': ro,
  'zh': zhCN,
  'fi': fi,
  'sv-djurpark': sv,
  'sv-industri': sv,
  'uk': enGB,
  'it': it,
  'he': he,
  'lt': lt,
}

/**
 * Format date/time using current locale
 * @param value
 * @param {"date" | "time" | "datetime"} formatType
 * @return {string}
 */
export function formatDate(value: any, formatType: 'date'|'time'|'human'|'datetime'|'interval'|'taskdate'|'taskday'|'taskweekday'|'taskdaytime' = 'datetime') {
  let valueFns;
  // Value send bata API can be also null, escape time formatting
  if (!value) {
    return;
  }
  // Some screens controller are already parsing as Date and sending to the pipe
  if (value instanceof Date)  {
    valueFns = value;
  } else if (typeof value === 'string') {
    valueFns = parseISO(value);
  } else {
    //UnixDateTime
    valueFns = new Date(value);
  }

  const locale = locales[UserInfo['locale']];
  switch (formatType) {
    case 'date': {
      return format(valueFns, 'P', { locale });
    }
    case 'time': {
      return format(valueFns, 'p', { locale });
    }
    case 'human': {
      return formatDistanceToNow(valueFns, { addSuffix: true, locale });
    }
    case 'interval': {
      return formatDistanceToNow(valueFns, { addSuffix: false, locale });
    }
    case 'taskdate': {
      return format(valueFns, 'P', { locale });
    }
    case 'taskday': {
      if (valueFns.getFullYear() !== (new Date()).getFullYear()) {
        return format(valueFns,  'PPPP', { locale });
      }
      return format(valueFns,'PP' , { locale });
    }
    case 'taskweekday': {
      if (valueFns.getFullYear() !== (new Date()).getFullYear()) {
        return format(valueFns, 'ccc, d MMMM yyyy', { locale });
      }
      return format(valueFns, 'ccc, d MMMM', { locale });
    }
    case 'taskdaytime': {
      if (valueFns.getFullYear() !== (new Date()).getFullYear()) {
        return format(valueFns, 'ccc, d MMMM yyyy, p', { locale });
      }
      return format(valueFns, 'ccc, d MMMM, p', { locale });
    }
    case 'datetime': {
      return format(valueFns, 'Pp', { locale });
    }
    default: {
      return format(valueFns, 'Pp', { locale });
    }
  }
}
