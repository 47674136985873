import { OfflineHandlerService } from '../../offline/handler.service';
import { Injectable } from '@angular/core';
import {ApiService} from '../../api.service';
import {HelperService} from '../../helper.service';
import RouteService from '../../route.service';
import {IRfidTag, IRfidTagPivot} from '../../../interfaces/rfid-tag.interface';
import {ICategory} from '../../../interfaces/category.interface';
import {IObjectPosition} from '../../../interfaces/object-position.interface';

/** Custom injection dependent on the system state. */
@Injectable({
  providedIn: 'root'
})
export default class RfidClientService {
  public constructor(
    private readonly offlineSrv: OfflineHandlerService,
    private readonly apiService: ApiService,
    private helperService: HelperService,
    private routeSrv: RouteService,
  ) {
  }

  /**
   * Reload the Tags list stored in the db
   * @public
   */
  public async reloadTags(): Promise<void> {
    await this.helperService.showLoader();
    return this.apiService.get('/rfid/tags/')
    .then(rfidTags => {
      this.helperService.companyRfidTags = rfidTags;
      this.helperService.dismissLoader();
    });
  }

  /**
   * Handles Replace to DB
   *
   * @param tag_uid
   * @param tagData
   * @public
   */
  public replaceTagInDatabase(tag_uid: string, tagData: IRfidTag): Promise<IRfidTag> {
    tagData.tag_uid = tag_uid;
    return this.apiService.put('/rfid/tag', tagData).then((response: IRfidTag) => {
      return response;
    }).catch((err) => {
      return err;
    });
  }

  /**
   * Handles save tag to DB
   *
   * @param tag_uid
   * @param data
   */
  public saveTagToDatabase(tag_uid: string, data: IRfidTag): Promise<IRfidTag> {

    const tagData: IRfidTag = {
      tag_uid: tag_uid,
      taggables_id: data.taggables_id,
      taggables_type: data.taggables_type,
      objectable_id: data.objectable_id,
      objectable_type: data.objectable_type,
    };
    return this.apiService.post('/rfid', tagData)
    .then((response: IRfidTag) => {
      return response;
    });
  }

  /**
   * Handles Delete tag in DB
   *
   * @param data
   */
  public deleteTag(data = {}): Promise<IRfidTag | void> {
    this.helperService.showLoader();
    return this.apiService.post('/rfid/tag/', data).then((deletedTag: IRfidTag) => {
      this.helperService.dismissLoader();
      return deletedTag;
    }).catch(() => {
      this.helperService.dismissLoader();
    });
  }

  /**
   * Add a new Objectable to a tag
   * Can be improved in the future when backend will be renew
   * @param tagData
   * @param nfcTagId
   */
  public replaceTag(tagData: IRfidTag, nfcTagId: string): Promise<IRfidTag> {
    return this.replaceTagInDatabase(nfcTagId, tagData).then((rfidTag) => {
        return rfidTag;
      }).catch((err) => {
        return err;
    });
  }

  /**
   * Find a tag by its tagUid
   * @param tag_uid
   * @public
   */
  public async findTag(tag_uid: string): Promise<IRfidTag> {
    const isOfflineEnable = await this.offlineSrv.isOfflineEnable();
    return new Promise((resolve, reject) => {

      if (isOfflineEnable) {
        const tags = this.helperService.companyRfidTags.filter(tag => tag.tag_uid === tag_uid);
        (tags.length) ? resolve(tags[0]) : reject(tags);
      }
      try {
        resolve(this.apiService.get('/rfid/tags/tag_uid/' + tag_uid + '?with[]=taggables', {}, {disableErrorHandler: true}));
      } catch (error) {
        reject(error)
      }
    });
  }


  /**
   * connectCategoryToTag
   * @param tagUid
   * @param tag_uid
   * @param objectId
   * @param objectableType
   * @param savedCategory
   * @param categoryToSave
   * @public async
   */
  public async connectCategoryToTag(tagUid: number, tag_uid: string, objectId: number, objectableType: string, savedCategory: any[], categoryToSave: ICategory[]): Promise<void> {
    // Check if a category is selected

    if (categoryToSave.length > 0) {
      // Create the tagData with the last Category selected by the user.
      // In the deviation edit screen, it will auto fill the inputlist till this category/subcategory.
      const tagData: IRfidTag = {
        taggables_id: categoryToSave[categoryToSave.length - 1].id,
        taggables_type: this.helperService.protocol.rfidTagTypes.CATEGORY,
        objectable_id: objectId,
        objectable_type: objectableType
      };

      // If a category already registered to the tag
      // We need to delete last category and then recreate one
      if (savedCategory.length > 0) {
        await this.unConnectCategoryToTag(tagUid, tag_uid, objectId, objectableType, savedCategory);
      }

      // Register a new category to this tag
      await this.saveTagToDatabase(tag_uid, tagData);
    } else {
      // If no category are selected
      // Check if the tag is already linked to a category and delete it
      if (savedCategory.length > 0) {
        await this.unConnectCategoryToTag(tagUid, tag_uid, objectId, objectableType, savedCategory);
      }
    }
  }

  /**
   * unConnectCategoryToTag
   * delete input Category to a selected tag
   * @param tagUid
   * @param tag_uid
   * @param objectId
   * @param objectableType
   * @param savedCategory
   * @public async
   */
  public async unConnectCategoryToTag(tagUid: number, tag_uid: string, objectId: number, objectableType: string, savedCategory: any): Promise<void> {
    const tagToDelete: IRfidTag = {
      uid: tagUid,
      tag_uid: savedCategory.tag_uid,
      taggables_id: savedCategory[0].id,
      taggables_type: this.helperService.protocol.rfidTagTypes.CATEGORY,
      objectable_id: objectId,
      objectable_type: objectableType
    };

    await this.deleteTag(tagToDelete);
  }


  /**
   * connectPositionsToTag
   * Connect all selected positions if needed
   * @param tagUid,
   * @param tag_uid,
   * @param objectId,
   * @param objectableType,
   * @param positionsSaved,
   * @param positionsSelected - Created by the select-object-position.component
   * @public async
   */
  public async connectPositionsToTag(tagUid: number, tag_uid: string, objectId: number, objectableType: string,  positionsSaved: IObjectPosition[], positionsSelected: any[]): Promise<void> {
    // if there are/is selected position to save
    if (positionsSelected.length > 0) {
      // Check if a value from saved positions is not anymore selected and prepare to delete
      const positionsToDelete = positionsSaved.filter(x => !positionsSelected.includes(x));

      // Delete all non positions selected for this tag
      if (positionsToDelete.length > 0) {
        await this.unConnectPositionsToTag(tagUid, tag_uid, objectId, objectableType, positionsToDelete);
      }

      // Select all positions that are not saved already to prepare its to save
      const positionsToSave = positionsSelected.filter(x => !positionsSaved.includes(x));

      // Positions to save.
      for (const pos of positionsToSave) {
        // When the position is a free text, the position has no id.
        // Set the id to the positiongroup
         const posId = (pos.id === undefined) ? pos.object_position_group_id : pos.id;

         const tagData: IRfidTag = {
          taggables_id: posId,
          taggables_type: this.helperService.protocol.rfidTagTypes.POSITION,
          objectable_id: objectId,
          objectable_type: objectableType
        };
        await this.saveTagToDatabase(tag_uid, tagData);
      }
    } else {
      // selected position/positionsToSave are automatically filled by the resolve
      // So if savedPosition is empty we need to delete savedPositions
      await this.unConnectPositionsToTag(tagUid, tag_uid, objectId, objectableType, positionsSaved);
    }
  }

  /**
   * unConnectPositionsToTag
   * Delete all "positionsToDelete" positions connected to this tag in the DB
   * @param tagUid
   * @param tag_uid
   * @param objectId
   * @param objectableType
   * @param positionsToDelete - Created by the select-object-position.component
   * @public async
   */
  public async unConnectPositionsToTag(tagUid: number, tag_uid: string, objectId: number, objectableType: string, positionsToDelete: any[]): Promise<void> {
    // Delete all positionsToDelete positions for this tag
    for (const pos of positionsToDelete) {
      const tagToDelete: IRfidTag = {
        uid: tagUid,
        tag_uid: tag_uid,
        taggables_id: pos.id,
        taggables_type: this.helperService.protocol.rfidTagTypes.POSITION,
        objectable_id: objectId,
        objectable_type: objectableType,
      };
      await this.deleteTag(tagToDelete);
    }
  }

  /**
   * getConnectedCategoryAndPositions
   * Get all connected positions and category for an objectid and a tag
   * @param objectId
   * @param objectType
   * @param rfidTag
   */
  getConnectedCategoryAndPositions(objectId: number, objectType: string, rfidTag: IRfidTagPivot): Promise<{
    category: ICategory,
    position: IObjectPosition[]
  }> {
    const url = objectType === 'round' ? 'rounds' : 'units';
    const tagUid = rfidTag.rfid_tag_uid;

    const categoryUrl = this.routeSrv.makeUrl(this.routeSrv.PATHS.rfid.tags.categories, {
      tag_uid: tagUid,
      objectType: url,
      objectId: objectId
    });

    const positionsUrl = this.routeSrv.makeUrl(this.routeSrv.PATHS.rfid.tags.positions, {
      tag_uid: tagUid,
      objectType: url,
      objectId: objectId
    });

    const categoryRequest = this.apiService.get(categoryUrl);
    const positionsRequest = this.apiService.get(positionsUrl);

    return Promise.all([categoryRequest, positionsRequest])
    .then(([categoriesRes, positionsRes]) => {
      const category = categoriesRes?.[0]?.categories?.[0] ?? null;
      return {
        category,
        position: positionsRes
      };
    })
    .catch(error => {
      console.error('Error fetching category and positions:', error);
      return {
        category: null,
        position: []
      };
    });
  }

  public async connectTagToUnitOrRound(objectId: number, objectType: string, tag_uid: string) {
    const tagData: IRfidTag = {
      taggables_id: objectId,
      taggables_type: objectType,
      objectable_id: objectId,
      objectable_type: objectType
    };
    // Link the tag to a new unit
    await this.replaceTagInDatabase(tag_uid, tagData);
  }
}
