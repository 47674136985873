import { Injectable } from '@angular/core';
import { ApiService } from '../../../api.service';
import RouteService from '../../../route.service';
import {INotificationList} from '../../../../interfaces/notification.interface';

@Injectable({
  providedIn: 'root'
})
export class NotificationsClient {
  constructor(
    private readonly apiService: ApiService,
    private readonly routeService: RouteService,
  ) {
  }

  public readSystemNotification(id: string): Promise<void> {
    return this.apiService.post(
      this.routeService.makeUrl(
        this.routeService.PATHS.notifications.system.read,
        { id }
      ),
    );
  }

  public getSystemNotifications(): Promise<INotificationList> {
    return this.apiService.get(
      this.routeService.makeUrl(
        this.routeService.PATHS.notifications.system.list
      ),
    );
  }
}
