import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  Optional,
  OnDestroy,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormGroupDirective } from '@angular/forms';
import { FormValidate } from '../form-validate';
import { KeyboardService } from '../../services/keyboard/keyboard.service';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputComponent),
    multi: true
  }]
})
export class InputComponent extends FormValidate implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() placeholder = '';
  @Input() type = 'text';
  @Input() formControlName?: string;
  @Input() pattern?: string;
  @Input() id?: string;
  @Input() suffix?: string;
  @Input() iconClass?: string;
  @Input() disabled = false;
  @Input() maxlength?: number;
  @Input() smallSize;
  @Input() min?: number;
  @Input() autocapitalize = true;

  @Output() ionChange = new EventEmitter();
  @Output() ionEnter = new EventEmitter();

  private value?: string;

  public classes = [];
  public isInputGroup = false;
  public iconShow = false;

  constructor(
    public keyboardService: KeyboardService,
    @Optional() protected controlContainer: FormGroupDirective
  ) {
    super(controlContainer);
  }

  ngOnInit() {
    this.initValidator();

    if (this.suffix) {
      this.isInputGroup = true;
    }
    if (this.iconClass) {
      this.iconShow = true;
    }
  }

  ngOnDestroy() {
    this.destroyValidator();
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState ? (isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * get accessor for currentValue
   * @return {any}
   */
  get currentValue(): any {
    return this.value;
  }

  /**
   * set accessor for currentValue
   * @param v
   */
  set currentValue(v: any) {
    if (v !== this.value) {
      this.value = v;
      this._onChange(v);
      this.ionChange.emit(v);
    }
  }

  /**
   * Fires on each keyup event
   * @param event
   */
  public onKeyUp(event) {
    if (event && (event.which === 13 || event.key === 'Enter')) {
      // remove focus from input to close keyboard
      if (event.target && event.target.blur) {
        event.target.blur();
      }

      this.ionEnter.emit(this.value);
    }
  }

  /**
   * Call if input was "touched"
   * @private
   */
  private _onTouched = () => {};

  /**
   * Call if value was changed inside our component
   * @param _
   * @private
   */
  private _onChange = (_: any) => {};

  public inputElChanged($evt) {
    this.currentValue = $evt.target.value;
  }
}
