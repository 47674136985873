import { Component } from '@angular/core';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-side-bar-menu-phone-support',
  templateUrl: './side-bar-menu-phone-support.component.html',
  styleUrls: ['./side-bar-menu-phone-support.component.scss']
})
export class SideBarMenuPhoneSupportComponent {

  constructor() { }

  public callToTheSupport(): void {
    window.location.href = 'tel:' + HelperService.getSupportPhone();
  }

  public get supportPhoneFormatted(): string {
    return HelperService.getSupportPhoneFormatted();
  }

}
