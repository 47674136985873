<ion-header>
  <div class="modal-header ion-margin-bottom">
    {{title}}
    <i class="fal fa-window-close" (click)="closeModal()" tappable></i>
  </div>
</ion-header>
<ion-content>
  <div class="ion-margin-bottom">
    <app-select-placeable-object  [unitsOnly]="unitsOnly"
                                  [roundsOnly]="roundsOnly"
                                  [writableOnly]="writableOnly"
                                  [isPerformCheck]="isPerformCheck"
                                  [isRequired]="false"
                                  [rfidTag]="rfidTag"
                                  [(ngModel)]="selectedPlaceableObject"
                                  (objectChange)="onObjectChange($event)"
                                  (placeChange)="onPlaceChange($event)">
    </app-select-placeable-object>
  </div>

  <div class="buttons ion-padding-vertical" *ngIf="!allowedSetupRFID">
    <ion-row>
      <ion-col *ngIf="allowedRFID">
        <app-button-rfid
                [scanMethod]="RfidScanMethod.GET_TAG_UID"
                [requireRfidScan]="true"
                (rfidTagUidChange)="callBackButtonRfid($event)"
                [rfidTagUid]="rfidTag">
        </app-button-rfid>
      </ion-col>
      <ion-col class="ion-text-right">
        <app-button (onClick)="clickNext()" iconClass="far fa-angle-right" iconAlign="right" [disabled]="!selectedObject">{{'actions.next' | lang}}</app-button>
      </ion-col>
    </ion-row>
  </div>
  <div class="buttons ion-padding-vertical" *ngIf="allowedSetupRFID">
    <ion-row>
      <ion-col class="ion-text-right">
        <app-button-rfid
                [scanMethod]="RfidScanMethod.GET_TAG_UID"
                [requireRfidScan]="rfidSetupRequireScan"
                (rfidTagUidChange)="callBackButtonRfid($event)"
                [rfidTagUid]="rfidTag">
        </app-button-rfid>
      </ion-col>
    </ion-row>
  </div>
</ion-content>
