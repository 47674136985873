import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter, forwardRef, Optional } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormGroupDirective } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { UserAppPreferencesService } from '../../services/user-app-preferences.service';
import { FormValidate } from '../form-validate';
import { LangService } from '../../services/lang.service';

@Component({
  selector: 'app-select-workplace-section',
  templateUrl: './select-workplace-section.component.html',
  styleUrls: ['./select-workplace-section.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectWorkplaceSectionComponent),
      multi: true,
    },
  ],
})
export class SelectWorkplaceSectionComponent extends FormValidate implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  public isDisabled = false;
  public workplacesPromise;
  public workplacesLoading;
  public sectionsList = [];

  private favorites = {
    workplace: null,
    section: null,
  };
  private selected = {
    workplace: null,
    section: null,
  };
  private workplaces;
  private maxWorkplaces = 100;
  private reinitWorkplaceOnClose = false;
  public allSectionsChecked = false;

  @Input() dependables;
  @Input() multiple = false;
  @Input() writableOnly = true;
  @Input() workplaceOnly = false;
  @Input() company = null;
  @Input() isRequired = false;
  @Input() showLabels = true;
  @Input() availableWorkplacesList = [];
  @Input() availableSectionsList = [];
  @Input() withSelectAllButton = false;
  @Input() restrictNumberOfSelectedSite = null;
  @Input() withEmptySection = false;
  @Input() withFavorite = true;
  @Input() preset = { workplaces: [], sections: [], objects: [], allObjectsChecked: false, allSectionsChecked: false, withEmptySection: false };

  @Output() changed = new EventEmitter();
  @Output() withEmptySectionChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() allSectionsCheckedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private langService: LangService, private apiService: ApiService, private userAppPreferencesService: UserAppPreferencesService, @Optional() protected controlContainer: FormGroupDirective) {
    super(controlContainer);
  }

  ngOnInit() {
    this.fetchWorkplaces();
    this.initValidator();
  }

  ngOnDestroy(): void {
    this.destroyValidator();
  }

  ngOnChanges(changes) {
    if (changes.company && changes.company.previousValue) {
      this.favorites = {
        workplace: null,
        section: null,
      };
      this.selectedWorkplace = null;
      this.selectedSection = null;
      this.onWorkplaceChange();
      this.fetchWorkplaces();
    }
    if (changes.withEmptySection && changes.withEmptySection.currentValue) {
      this.withEmptySection = changes.withEmptySection.currentValue;
    }
    if (changes.preset && changes.preset.previousValue === undefined && changes.preset.currentValue) {
      // only execute when the preset has been setup once from offline mode page
      this.allSectionsChecked = changes.preset.currentValue.allSectionsChecked;
      this.withEmptySection = changes.preset.currentValue.withEmptySection;
      if (changes.preset.currentValue.workplace?.length > 0) {
        this.selectedWorkplace = changes.preset.currentValue.workplace;
      }
      if (changes.preset.currentValue.section?.length > 0) {
        this.selectedSection = changes.preset.currentValue.section;
      }
      if (changes.preset.currentValue.workplace?.length > 0 || changes.preset.currentValue.section?.length > 0) {
        this.onWorkplaceChange();
        this.onAllSectionsChecked();
      }
    }
  }

  /**
   * get accessor for selectedWorkplace
   * @return
   */
  get selectedWorkplace(): any {
    return this.selected.workplace;
  }

  /**
   * set accessor for selectedWorkplace
   * @param v
   */
  set selectedWorkplace(v: any) {
    if (v !== this.selected.workplace) {
      this.selected.workplace = v;
      this.onChange();
    }
  }

  /**
   * get accessor for selectedSection
   * @return
   */
  get selectedSection(): any {
    return this.selected.section;
  }

  /**
   * set accessor for selectedSection
   * @param v
   */
  set selectedSection(v: any) {
    if (v !== this.selected.section) {
      this.selected.section = v;
      this.onChange();
    }
  }

  onChange() {
    this._onChange(Object.assign({}, this.selected));
  }

  /**
   * calls when setting ngModel from outside
   * @param obj
   */
  writeValue(obj: any): void {
    if (!this.multiple && obj) {
      if (obj.workplace_id || obj.section_id) {
        // set workplace/section based on workplace_id and section_id
        this.selectWorkplaceById(obj.workplace_id).then(() => this.selectSectionById(obj.section_id));
      } else if (obj.workplace) {
        this.selected.workplace = obj.workplace;
        this.onWorkplaceChange();

        if (obj.section) {
          this.selected.section = obj.section;
          this.onSectionChange();
        }
      }
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /**
   * triggers on workplace search
   * @param event
   */
  public onWorkplacesSearch(event) {
    let options = {};

    if (event) {
      options = { query: event };
    }

    this.fetchWorkplaces(options);
  }

  /**
   * triggers on workplace change
   */
  public onWorkplaceChange() {
    this.changed.emit(this.selected);
    this.fetchSections();
  }

  /**
   * triggers on workplace dropdown list close
   */
  public onWorkplaceSelectClose() {
    if (this.reinitWorkplaceOnClose) {
      this.fetchWorkplaces();
    }
  }

  public onSectionChange() {
    this.changed.emit(this.selected);
  }

  /**
   * add/remove item in favorites list
   * @param event
   * @param type
   * @param item
   */
  public toggleFavorite(event, type, item) {
    event.stopPropagation();

    item.isFavorite = !item.isFavorite;

    if (item.isFavorite) {
      this.userAppPreferencesService.addToFavorites(type, item);

      if (!this.favorites[type]) {
        this.favorites[type] = [];
      }
      this.favorites[type].push(item);
    } else {
      this.userAppPreferencesService.removeFromFavorites(type, item);
      if (this.favorites[type]) {
        this.favorites[type] = this.favorites[type].filter(favorite => favorite.id !== item.id);
      }
    }

    if (type === 'workplace') {
      this.reinitWorkplaceOnClose = true;
    }
  }

  public async selectWorkplaceById(workplaceId) {
    if (!workplaceId) {
      return;
    }

    // first of all try to find in current list of workplaces
    let foundWorkplace = this.workplaces?.length ? this.workplaces.find(workplace => workplace.id === workplaceId) : null;

    // in case if current list of workplaces is empty or there is no such workplace - find it using api
    if (!foundWorkplace) {
      foundWorkplace = await this.apiService.get('/workplaces/' + workplaceId, null, { cache: true });
    }

    if (!foundWorkplace) {
      return;
    }

    this.selected.workplace = foundWorkplace;
    this.onChange();
    this.onWorkplaceChange();
  }

  public selectSectionById(sectionId) {
    if (!sectionId || !this.selected.workplace.sections?.length) {
      return;
    }

    const foundSection = this.selected.workplace.sections.find(section => section.id === sectionId);
    if (!foundSection) {
      return;
    }

    this.selected.section = foundSection;
    this.onChange();
    this.onSectionChange();
  }

  /**
   * Call if input was "touched"
   * @private
   */
  private _onTouched = () => {};

  /**
   * Call if value was changed inside our component
   * @param _
   * @private
   */
  private _onChange = (_: any) => {};

  /**
   * get list of workplaces
   * @param options
   */
  private fetchWorkplaces(options = <any>{}) {
    this.reinitWorkplaceOnClose = false;

    const defaultOptions = <any>{
      compact: true,
      query: '',
      per_page: this.maxWorkplaces,
    };
    const favoritesOptions = <any>{
      compact: true,
      query: '',
    };
    if (this.writableOnly) {
      defaultOptions.writable = true;
      favoritesOptions.writable = true;
    }
    if (this.company && this.company.id) {
      defaultOptions.user_id = this.company.id;
      favoritesOptions.user_id = this.company.id;
    }

    options = Object.assign(defaultOptions, options);

    this.workplacesLoading = true;
    this.workplacesPromise = this.userAppPreferencesService.getFavoriteIds('workplace', favoritesOptions.user_id || null).then(favorites => {
      const promises = [];
      if (this.availableWorkplacesList.length !== 0) {
        /* For manual list of Workplaces */
        if (!options.query) {
          promises.push(Promise.resolve(this.availableWorkplacesList));
        } else {
          promises.push(Promise.resolve(this.availableWorkplacesList.filter(workplace => workplace.name.toLowerCase().indexOf(options.query.toLowerCase()) >= 0)));
        }
      } else {
        promises.push(this.apiService.get('/workplaces', options, { cache: true }));
      }
      const type = 'workplace';

      if (this.favorites[type]) {
        promises.push(Promise.resolve(this.favorites[type]));
      } else if (favorites && favorites.length) {
        favoritesOptions.ids = favorites;
        promises.push(this.apiService.get('/workplaces', favoritesOptions, { cache: true }));
      } else {
        promises.push(Promise.resolve([]));
      }

      return Promise.all(promises).then(results => {
        this.workplacesLoading = false;
        let workplaces = results[0];
        if (workplaces.data) {
          workplaces = workplaces.data;
        }
        this.workplaces = workplaces;
        this.favorites[type] = results[1] || [];

        if (!this.favorites[type] || !this.favorites[type].length) {
          return this.workplaces;
        }

        const groupedWorkplaces = [];
        const favoriteIds = [];

        const favoriteWorkplaces = this.favorites[type].filter(favorite => {
          favorite.isFavorite = true;
          favoriteIds.push(favorite.id);

          if (!options.query) {
            return true;
          }

          return favorite.name.toLowerCase().indexOf(options.query.toLowerCase()) >= 0;
        });

        if (favoriteWorkplaces.length) {
          groupedWorkplaces.push({
            title: this.langService.t('general.favorites'),
            workplaces: favoriteWorkplaces,
          });
        }

        const notFavorites = this.workplaces.filter(workplace => favoriteIds.indexOf(workplace.id) < 0);

        if (notFavorites.length) {
          groupedWorkplaces.push({
            title: this.langService.t('workplaces.plural'),
            workplaces: notFavorites,
          });
        }
        return groupedWorkplaces;
      });
    });
  }

  /**
   * get list of sections
   * @param term
   * @return
   */
  private async fetchSections(term = '') {
    let selectedWorkplace = this.selectedWorkplace;
    if (!selectedWorkplace) {
      this.selectedSection = null;
      this.sectionsList = [];
      return;
    }

    const favoriteSectionIds = await this.userAppPreferencesService.getFavoriteIds('section');

    if (!Array.isArray(selectedWorkplace)) {
      selectedWorkplace = [selectedWorkplace];
    }

    // get list of all sections from all selected workplaces
    const selectedWorkplaceIds = [];
    let sectionsList = [];
    selectedWorkplace.forEach(workplace => {
      selectedWorkplaceIds.push(workplace.id);

      if (!workplace.sections || !workplace.sections.length) {
        return;
      }

      let availableSectionsList = [];
      if (this.availableWorkplacesList.length !== 0 && this.availableSectionsList.length !== 0) {
        availableSectionsList = this.availableSectionsList.filter(section => section.workplace_id === workplace.id);
      } else {
        availableSectionsList = workplace.sections;
      }

      availableSectionsList.forEach(section => {
        section.workplace_name = workplace.name;
      });

      if (term) {
        sectionsList = sectionsList.concat(availableSectionsList.filter(section => section.name.toLowerCase().indexOf(term) > -1));
      } else {
        sectionsList = sectionsList.concat(availableSectionsList);
      }
    });

    // deselect sections based on selected workplaces
    if (this.selectedSection) {
      if (Array.isArray(this.selectedSection)) {
        this.selectedSection = this.selectedSection.filter(section => selectedWorkplaceIds.indexOf(section.workplace_id) >= 0);
      } else {
        if (selectedWorkplaceIds.indexOf(this.selectedSection.workplace_id) < 0) {
          this.selectedSection = null;
        }
      }
    }

    // get list of favorite sections
    let favoriteSections = [];
    if (favoriteSectionIds && favoriteSectionIds.length) {
      favoriteSections = sectionsList.filter(section => {
        if (favoriteSectionIds.indexOf(section.id) >= 0) {
          section.isFavorite = true;
          return true;
        }
        return false;
      });
    }

    if (!favoriteSections || !favoriteSections.length) {
      this.sectionsList = sectionsList;
      return;
    }

    const groupedSections = [];
    groupedSections.push({
      title: this.langService.t('general.favorites'),
      sections: favoriteSections,
    });

    const notFavorites = sectionsList.filter(section => !section.isFavorite);

    if (notFavorites.length) {
      groupedSections.push({
        title: this.langService.t('sections.plural'),
        sections: notFavorites,
      });
    }

    this.sectionsList = groupedSections;
  }

  public onAllSectionsChecked() {
    if (this.allSectionsChecked) {
      this.selected.section = [];
      this.withEmptySection = true;
      this.withEmptySectionChange.emit(this.withEmptySection);
    }
    this.allSectionsCheckedChange.emit(this.allSectionsChecked);
    this.onChange();
  }
  public onChangeWithEmptySection() {
    this.withEmptySectionChange.emit(this.withEmptySection);
    this.onChange();
  }

  /** to prevent no render of section part if the selected sites has only empty Section List */
  public numberOfSelectedWorkplace(): number {
    return this.selected?.workplace?.length || 0;
  }
}
