import { Component } from '@angular/core';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-side-bar-menu-email-support',
  templateUrl: './side-bar-menu-email-support.component.html',
  styleUrls: ['./side-bar-menu-email-support.component.scss']
})
export class SideBarMenuEmailSupportComponent {

  constructor() { }

  public sendEmailToSupport(): void {
    window.location.href = 'mailto:' + HelperService.getSupportEmail();
  }

  public get supportEmail(): string {
    return HelperService.getSupportEmail();
  }

}
