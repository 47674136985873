import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { NotificationCenterComponent } from './notification-center.component';
import { SharedModule } from '../../components/shared.module';
import { ModalService } from '../../services/modal.service';
import { MessageComponent } from '../../components/notification-center/message/message.component';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    FormsModule,
    SharedPipesModule
  ],
  declarations: [
    NotificationCenterComponent,
    MessageComponent
  ],
  providers: [ModalService],
})
export class NotificationCenterModule {}
