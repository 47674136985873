<ion-menu
        id="sidebar-menu"
        side="end"
        menuId="sidebar-menu"
        contentId="main-content"
        type="overlay"
        swipeGesture="false">
    <ion-header *ngIf="isEnabled">
      <ion-toolbar>
        <div class="toolbar">
          <button tappable (click)="close()">
            <i class="far fa-xmark"></i>
          </button>
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content class="content" *ngIf="isEnabled">
      <ion-list>
        <ion-item
          class="menu-item-link blue-color"
          lines="full"
          tappable
          (click)="showNotificationCenter()">
          <ion-avatar slot="start">
            <div class="notification-icon-wrapper">
              <i class="fal fa-bell round-icon"></i>
              <span *ngIf="(hasUnread$ | async)" class="badge"></span>
            </div>
          </ion-avatar>
          <ion-label>
            <h2>{{'notifications.notificationCenter' | lang}}
            </h2>
          </ion-label>
        </ion-item>
        <ion-item
          class="menu-item-link"
          lines="full"
          tappable
          *ngIf="allowedFeatures && allowedFeatures[AppFeature.OFFLINE_MODE]"
          (click)="offlineMode()">
          <div class="menu-item-link-wrapper">
            {{'offline.offline-mode' | lang}} <i class="fas fa-chevron-right fa-xs"></i>
          </div>
        </ion-item>
        <ion-item class="menu-item-section-header" lines="full">
          {{'app.my-account' | lang}}
        </ion-item>
        <ion-item
          class="menu-item-link"
          lines="full"
          tappable
          (click)="worktimeHistory()">
          <div class="menu-item-link-wrapper">
            {{'worktime.workTime' | lang}} <i class="fas fa-chevron-right fa-xs"></i>
          </div>
        </ion-item>
        <ion-item
          class="menu-item-link"
          *ngIf="canLinkAzureAccount && !isOfflineMode && !samlAuthService.hasConnected"
          lines="full"
          tappable
          (click)="linkAzureAccount()">
          <div class="menu-item-link-wrapper">
            {{'saml.link-azure-account' | lang}} <i class="fas fa-chevron-right fa-xs"></i>
          </div>
        </ion-item>
        <ion-item
          class="menu-item-link"
          *ngIf="canSwitchCompany && !isOfflineMode"
          lines="full"
          tappable
          (click)="switchCompany()">
          <div class="menu-item-link-wrapper">
            {{'app.change-company' | lang}} <i class="fas fa-chevron-right fa-xs"></i>
          </div>
        </ion-item>
        <ion-item class="menu-item-section-header" lines="full">
          {{'app.support' | lang}}
        </ion-item>
        <ion-item
          class="menu-item-link"
          lines="full"
          tappable
          (click)="goToCheckProofAcademy()">
          <div class="menu-item-link-wrapper">
            {{'app.checkproof-academy' | lang}} <i class="fas fa-chevron-right fa-xs"></i>
          </div>
        </ion-item>
        <ion-item
          class="menu-item-link blue-color"
          lines="full"
          tappable
          (click)="callToTheSupport()">
          <ion-avatar slot="start">
            <i class="fal fa-phone round-icon"></i>
          </ion-avatar>
          <ion-label>
            <h2>{{ 'actions.callSupport' | lang }}</h2>
            <h3>
              <a>{{ supportPhoneFormatted }}</a>
            </h3>
          </ion-label>
        </ion-item>
        <ion-item
          class="menu-item-link blue-color"
          lines="full"
          tappable
          (click)="sendEmailToSupport()">
          <ion-avatar slot="start">
            <i class="fal fa-envelope round-icon"></i>
          </ion-avatar>
          <ion-label>
            <h2>{{ 'actions.emailSupport' | lang }}</h2>
            <h3>
              <a>{{ supportEmail }}</a>
            </h3>
          </ion-label>
        </ion-item>
        <ion-item
          class="menu-item-link blue-color"
          lines="full"
          tappable
          (click)="openIntercomChat()">
          <ion-avatar slot="start">
            <i class="fal fa-messages round-icon"></i>
          </ion-avatar>
          <ion-label>
            <h2>{{ 'actions.chat-with-support' | lang }}</h2>
            <h3>
              {{ 'actions.start-chat-conversation' | lang }}
            </h3>
          </ion-label>
        </ion-item>
        <ion-item class="menu-item-section-header" lines="full">
          {{'app.system' | lang}}
        </ion-item>
        <ion-item
          class="menu-item-link"
          *ngIf="canEditObjects && !isOfflineMode"
          lines="full"
          tappable
          (click)="editObject()">
          <div class="menu-item-link-wrapper">
            {{'actions.edit-existing' | lang}} <i class="fas fa-chevron-right fa-xs"></i>
          </div>
        </ion-item>
        <ion-item
          class="menu-item-link"
          *ngIf="canEditObjects && !isOfflineMode"
          lines="full"
          tappable
          (click)="createNewObject()">
          <div class="menu-item-link-wrapper">
            {{'actions.createNew' | lang}} <i class="fas fa-chevron-right fa-xs"></i>
          </div>
        </ion-item>
        <ion-item
          class="menu-item-link"
          *ngIf="allowedSetupRFID && !isOfflineMode"
          lines="full"
          tappable
          (click)="setupRfid()">
          <div class="menu-item-link-wrapper">
            {{'rfid.setupRfid' | lang}} <i class="fas fa-chevron-right fa-xs"></i>
          </div>
        </ion-item>
        <ion-item class="ion-item-no-padding" lines="full" tappable>
          <div class="bottom">
            <div class="new-version-notice" *ngIf="(showNewVersionNotice$ | async)?.newVersion">
              <a href="{{linkForUpdate}}">{{'app.new-version-available' | lang}}</a>
            </div>
            <p class="version">{{'app.version' | lang}} {{appVersionNumber}}</p>
            <p>
              <app-button iconClass="far fa-sign-out-alt color-danger" iconAlign="left" (onClick)="logout()">{{'actions.logOut' | lang}}</app-button>
            </p>
          </div>
        </ion-item>
      </ion-list>
    </ion-content>
</ion-menu>
