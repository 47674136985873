import { Injectable } from '@angular/core';
import { LangService } from './lang.service';
import escape from 'lodash/escape';
import { IServiceDate } from "../interfaces/service-date.interface";
import IIntervalDueTrigger from "../interfaces/due-data/interval-due-trigger.interface";
import IDueWithinValue from "../interfaces/due-within-value.interface";
import { formatDate } from "../utils/format-date.util";
import { typeOf } from 'mathjs';

@Injectable({
  providedIn: 'root'
})

export class FormatService {
  private readonly SECONDS_UNITS = 'sec';

  constructor(
    private langService: LangService
  ) {
  }

  /**
   * Format user name
   * @param user
   * @return {string}
   */
  public formatUserName(user: any): string {
    if (user) {
      return user.firstname + ' ' + user.lastname;
    }

    return this.langService.t('users.removedUser');
  }

  /**
   * Format value for "Due Within"
   * @param value
   * @param unit
   * @param absoluteValue
   * @return {string}
   */
  public dueWithinValue(value, unit = this.SECONDS_UNITS, absoluteValue = true) {
    let absValue = absoluteValue ? Math.abs(value) : value;
    let formattedValue = absValue.toString();

    if (unit === this.SECONDS_UNITS) {
      if (absValue >= 86400) {
        absValue = FormatService.formatSeconds(absValue);

        if (1 === absValue) {
          return this.langService.t(`dates.${value < 0 ? 'yesterday' : 'tomorrow'}`);
        }

        formattedValue = this.langService.t('dates.days-choice', {count: absValue});
        if (value < 0) {
          formattedValue += ' ' + this.langService.t('general.delayed');
        }
      } else {
        absValue = FormatService.formatSeconds(absValue, 'hour');
        formattedValue = this.langService.t('dates.hours-choice', {count: absValue});
        if (value < 0) {
          formattedValue += ' ' + this.langService.t('general.delayed');
        }
      }
    } else if (unit) {
      formattedValue = absValue + ' ' + unit;
    }

    return formattedValue;
  }

  /** Get due-within of service */
  public makeServiceDueWithinValues(dueData: IServiceDate): IDueWithinValue[] {
    if (dueData.is_smart_interval) { // Odometer & time interval
      return this.makeSmartIntervalDueWithinValues(dueData);
    }

    if (dueData.interval_due_trigger) { // Odometer-range  interval
      return this.makeOdometerRangeDueWithinValues(dueData.interval_due_trigger);
    }

    if (dueData.is_odometer) { // Odometer interval
      return [this.makeOdometerDueWithinValue(dueData)]
    }

    if (dueData.time_data) { // Time interval
      return [this.makeTimeDueWithinValue(dueData)];
    }

    return [];
  }

  /**
   * Transform user entered text to show it properly on the page
   * @param input
   * @return {any}
   */
  formatUserText(input: any): any {
    return escape(input).replace(/(?:\r\n|\r|\n)/g, '\n<br />\n');
  }

  /** Make due-withing of smart-interval */
  private makeSmartIntervalDueWithinValues(dueData: IServiceDate): IDueWithinValue[] {
    const serviceDates = dueData.smart_intervals.some(smartDue => smartDue.is_due) // If there overdue intervals
      ? dueData.smart_intervals // Upcoming + overdue
        .filter(serviceDate => serviceDate.is_due) // Get overdue only
        .sort((serviceDateA) => serviceDateA.is_odometer ? -1 : 0) // Prioritize odometer intervals
        .slice(0, 1) // Get the first one
      : dueData.smart_intervals; // Upcoming

    return [].concat(  // Convert due-items => due-within
      ...serviceDates
        .map(serviceDate => this.makeServiceDueWithinValues(serviceDate))
    );
  }

  /** Make due-withing of odometer-range */
  private makeOdometerRangeDueWithinValues(dueTrigger: IIntervalDueTrigger): IDueWithinValue[] {
    const odoSuffix = FormatService.getOdometerSuffix(dueTrigger.service_date);

    return dueTrigger
      ? [
        Object.assign(
          this.makeDueWithinValue(
            dueTrigger.service_date.value,
            odoSuffix ?? this.SECONDS_UNITS,
          ),
          {
            when: `${dueTrigger.service_date.value} ${odoSuffix ?? ''}`.trim(),
            isOdometer: true,
            isOverdue: true,
          } as Partial<IDueWithinValue>
        )
      ]
      : []
  }

  /** Make due-within of odometer */
  private makeOdometerDueWithinValue(dueData: IServiceDate): IDueWithinValue {
    const odoSuffix = FormatService.getOdometerSuffix(dueData);
    return Object.assign(
      this.makeDueWithinValue(
        dueData.interval_value,
        odoSuffix
      ),
      {
        isOdometer: true,
        when: `${dueData.interval_value} ${odoSuffix ?? ''}`.trim(),
      }
    )
  }

  /** Make due-within of time */
  private makeTimeDueWithinValue(dueData: IServiceDate): IDueWithinValue {
    return Object.assign(
      this.makeDueWithinValue(
        dueData.interval_value,
        this.SECONDS_UNITS
      ),
      {
        when: [
          formatDate(dueData.time_data.when_at, 'taskday'),
          FormatService.formatTime(dueData.time_data.when_at)
        ].join(' '),
      }
    )
  }

  /** Convert units to due-within */
  private makeDueWithinValue(value: number | string, units?: string): IDueWithinValue {
    const [resultValue, resultUnits] = this
      .dueWithinValue(value, units ?? undefined)
      .split(' ');
    const intValue = parseFloat(resultValue)

    return { // Decompose service's value
      value: Number.isNaN(intValue) ? resultValue : intValue,
      units: resultUnits ?? '',
      isOverdue: typeOf(value) === 'number' ? (value as number) < 0 : Number(value) < 0,
      isOdometer: false,
      when: '',
    };
  }

  /** Get measurement unit of odometer */
  private static getOdometerSuffix(dueData: IServiceDate): string | null {
    return dueData?.odometer_suffix ?? dueData.odometer_data?.suffix
  }

  /** Extract time part */
  private static formatTime(dateTime: string): string {
    const time = formatDate(dateTime, 'time')
    return time === '00:00' ? '' : time;
  }

  /**
   * format seconds to specific format (days, hours)
   * @param sec
   * @param unit
   * @return {*}
   */
  private static formatSeconds(sec, unit = 'day') {
    if (unit === 'day') {
      return Math.round(sec / 86400);
    } else if (unit === 'hour') {
      return Math.round(sec / 3600);
    }
    return sec;
  }
}
