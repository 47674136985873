import { Pipe, PipeTransform } from '@angular/core';
import { FormatService } from '../services/format.service';

@Pipe({
  name: 'formatUserName'
})

export class FormatUserName implements PipeTransform {
  constructor(
    private formatService: FormatService
  ) {}

  /**
   * Transform user using FormatService logic
   * @param user
   */
  transform(user: any): string {
    return this.formatService.formatUserName(user);
  }
}
