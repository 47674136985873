import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ModalService } from '../../services/modal.service';
import { NotificationService } from '../../services/notification.service';
import { NotificationFormatUtil } from '../../utils/format-notification-data.util';
import { AuthService } from '../../services/auth.service';
import { OfflineHandlerService } from '../../services/offline/handler.service';
import { SharedUserService } from '../../services/shared-user.service';

@Component({
  selector: 'app-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss'],
})

export class NotificationCenterComponent implements OnInit {
  private user;
  public notifications;
  public messages;
  public selectedCompany;

  public showNotifications = true;
  public isSynchronising: boolean;
  public canSwitchCompany: boolean;
  public isLoadingMoreMessages = false;
  public isLoadingMoreNotifications = false;

  public hasMoreMessages = false;
  public currentMessagePage;

  public hasMoreNotifications = false;
  public currentNotificationsPage;

  public hasUnreadMessages$ = this.notificationService.unreadMessagesCount.asObservable();
  public hasUnreadNotifications$ = this.notificationService.unreadNotificationsCount.asObservable();

  public isOffline: boolean;

  constructor(
    private modalService: ModalService,
    private notificationService: NotificationService,
    private notificationFormatUtil: NotificationFormatUtil,
    private authService: AuthService,
    private offlineHandlerService: OfflineHandlerService,
    private navController: NavController,
    private sharedUserService: SharedUserService
  ) { }

  ngOnInit() {
    this.offlineHandlerService.isOfflineEnable().then(isOffline => {
      this.isOffline = isOffline;
    });

    this.isSynchronising = true;

    this.authService.getCurrentUser().then(user => {
      this.user = user;
      this.selectedCompany = this.user.company;
      this.canSwitchCompany = (user.clones && user.clones.length > 1);
    }).then(() => {
      this.notificationService.setupNotifications(this.user).then(response => {
        this.initData(response);

        this.isSynchronising = false;
        this.notificationService.markAllNotificationsRead();
      });
    });
  }

  /**
   * Closing the notifications modal.
   */
  public closeModal() {
    this.modalService.close();
  }

  /**
   * Switch between notifications and messages in template
   *
   * @param type
   */
  public switchNotificationType(type) {
    this.showNotifications = (type.detail.value === 'notifications');
  }

  public formatNotificationText(text) {
    return this.notificationFormatUtil.formatText(text);
  }

  /**
   * Returns the correct icons string dependig on notification type
   *
   * @param type
   */
  public getNotificationIcon(type): string {
    return NotificationFormatUtil.getNotificationIcon(type);
  }

  /**
   * Get date/time of notification
   * @param notification
   * @return {any}
   */
  public getNotificationDate(notification) {
    if (notification.data && notification.data.created_at) {
      return notification.data.created_at;
    }

    return notification.created_at;
  }

  public switchCompany() {
    this.isSynchronising = true;
    this.showNotifications = true;

    this.currentMessagePage = 0;
    this.currentNotificationsPage = 0;

    this.notificationService.changeCompany(this.selectedCompany).then(response => {
      this.initData(response);

      this.notificationService.markAllNotificationsRead(this.selectedCompany.id);
      this.isSynchronising = false;
    });
  }

  /**
   * Loads more messages from notificationservice
   */
  public loadMoreMessages(): void {
    this.isLoadingMoreMessages = true;

    if (this.hasMoreMessages) {
      this.notificationService.getMoreMessages(this.selectedCompany.id, this.currentMessagePage + 1).then(response => {
        this.isLoadingMoreMessages = false;

        this.currentMessagePage = response.data.current_page;
        this.hasMoreMessages = (response.data.to < response.data.total);

        response.messages.forEach(message => {
          this.messages.push(message);
        });
      });
    }
  }

  /**
   * Loads more notifications from notificationservice
   */
  public loadMoreNotifications(): void {
    this.isLoadingMoreNotifications = true;

    if (this.hasMoreNotifications) {
      this.notificationService.getMoreNotifications(this.selectedCompany.id, this.currentNotificationsPage + 1).then(response => {
        this.isLoadingMoreNotifications = false;

        this.currentNotificationsPage = response.data.current_page;
        this.hasMoreNotifications = (response.data.to < response.data.total);

        response.notifications.forEach(notification => {
          this.notifications.push(notification);
        });
      });
    }
  }

  /**
   * Triggers when selecting a message in message listing
   *
   * @param message
   */
  public markMessageAsRead(message) {
    this.notificationService.markMessagesRead(message);
  }

  private initData(response) {
    const messageResponse = response[0];
    this.messages = messageResponse.messages || [];
    if (messageResponse.data) {
      this.currentMessagePage = messageResponse.data.current_page;
      this.hasMoreMessages = (messageResponse.data.to < messageResponse.data.total);
    } else {
      this.currentMessagePage = 0;
      this.hasMoreMessages = false;
    }

    const notificationsResponse = response[1];
    this.notifications = notificationsResponse.notifications || [];
    if (notificationsResponse.data) {
      this.currentNotificationsPage = notificationsResponse.data.current_page;
      this.hasMoreNotifications = (notificationsResponse.data.to < notificationsResponse.data.total);
    } else {
      this.currentNotificationsPage = 0;
      this.hasMoreNotifications = false;
    }
  }

  public goToNotification(notification) {
    const url = this.notificationService.getNavigateUrl(notification.data);

    if (!url) {
      return;
    }

    this.closeModal();

    if (notification.data.company_id && notification.data.company_id !== this.user.company.id) {
      this.sharedUserService.navigateTo(notification.data.company_id, url);
    } else {
      this.navController.navigateForward(url);
    }
  }
}
