import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {Observable, Subscription, take} from 'rxjs';
import {HelperService} from '../../../services/helper.service';
import {LangService} from '../../../services/lang.service';
import {RfidScanMethod} from '../../../enums';
import {NfcService} from '../../../services/nfc/nfc.service';
import {PlatformService} from '../../../services/platform/platform.service';
import {DialogComponent} from '../../../modals/dialog/dialog.component';
import {ModalService} from '../../../services/modal.service';
import RfidClientService from '../../../services/clients/rfid/rfid-client.service';
import {EnvironmentService} from '../../../services/environment.service';

@Component({
  selector: 'app-button-rfid',
  templateUrl: './button-rfid.component.html',
  styleUrls: ['./button-rfid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonRfidComponent implements OnInit, OnDestroy {
  @Input() scanMethod = RfidScanMethod.GET_TAG_UID;
  @Input() requireRfidScan = true;
  @Input() rfidTagUid: string;
  @Input() isSetupButton = false;

  @Output() requireRfidScanChange: EventEmitter<boolean> = new EventEmitter();
  @Output() rfidTagUidChange: EventEmitter<string> = new EventEmitter();

  public rfidAutoScan = false;
  private androidPopoverScanner: any;

  public scannedTagUid$: Observable<string>;
  private scannedTagUidSubscription$: Subscription = new Subscription();
  public buttonLabel: string = '';
  constructor(private rfidCltService: RfidClientService,
              private readonly nfcService: NfcService,
              private readonly platformService: PlatformService,
              private helperService: HelperService,
              private langService: LangService,
              private modalService: ModalService,
              private cdRef: ChangeDetectorRef,
              private environmentService: EnvironmentService) {
    this.scannedTagUid$ = this.nfcService.scannedTagUid$;
  }

  async ngOnInit() {
    if (RfidScanMethod.REPLACE_TAG === this.scanMethod)  {
      this.buttonLabel = 'actions.replace' ;
    } else {
      this.buttonLabel = 'rfid.scan';
    }
    if (!this.isSetupButton) {
      // setup auto scan for Android only for non setup button
      await this.subscribeToObservables();
    }
  }

  private async subscribeToObservables(): Promise<void> {
    if (this.platformService.isAndroid()) {
      await this.nfcService.startScanSession().then(() => {
        this.rfidAutoScan = true;
        this.cdRef.detectChanges();
      });
      this.scannedTagUidSubscription$ = this.scannedTagUid$.subscribe((x) => {
        if (this.androidPopoverScanner !== undefined) {
          this.androidPopoverScanner.dismiss();
        }
        this.triggerTagChange(x);
      });
    }
  }

  private triggerTagChange(tagUid: string) {
    if (this.scanMethod === RfidScanMethod.GET_TAG_UID || RfidScanMethod.REPLACE_TAG) {
      if (this.requireRfidScan) {
        // Reload tags List (usefull if superadmin updated it after user started app)
        this.rfidCltService.reloadTags().then(() => {
          this.rfidTagUidChange.emit(tagUid);
        });
      }
    }
    if (this.scanMethod === RfidScanMethod.VALIDATE_CHECKPOINT) {
      if (this.requireRfidScan) {
        this.validateAutoScannedTag(this.rfidTagUid, tagUid);
      }
    }
    if (this.isSetupButton && this.platformService.isAndroid()) {
      this.rfidAutoScan = false;
      this.scannedTagUidSubscription$.unsubscribe();
    }
  }

  /** Method use when the user use auto scan **/
  private validateAutoScannedTag(controlpointTag: string, scannedTag: string) {
    // Update the view
    if (controlpointTag === scannedTag) {
      this.requireRfidScanChange.emit(false);
    } else {
      this.helperService.showAlert({
        header: this.langService.t('rfid.messages.notCorrectTag'),
        buttons: [
          {
            text: this.langService.t('actions.close'),
            handler: () => {
            }
          }
        ]
      });
    }
  }
  ngOnDestroy(): void {
    // stopScanSession only for iOS to prevent Android system scan overflow
    if (this.platformService.isIos()) {
      this.nfcService.stopScanSession();
    } else if (this.platformService.isAndroid()) {
      this.scannedTagUidSubscription$.unsubscribe();
    }
  }

  // Method use when the user click on the button
  public async doRfidScan() {

    if (this.platformService.isIos()) {
      await this.nfcService.startScanSession();
      this.scannedTagUid$
      .pipe(
        take(1),
      ).subscribe((x) => {
        this.triggerTagChange(x);
        this.nfcService.stopScanSession();
      });
    } else if (this.platformService.isAndroid()) {
      if (this.isSetupButton) {
        await this.subscribeToObservables();
      }
      this.androidPopoverScanner = await this.modalService.open({
        component: DialogComponent,
        componentProps: {
          title: this.langService.t('rfid.title'),
          message: this.langService.t('rfid.prompt.interactWithTag'),
          showSpinner: true,
          buttons: [
            {
              text: this.langService.t('actions.cancel'),
              iconClass: 'fal fa-times color-danger',
              value: false,
              handler: async () => {
                // Create a popover Handler button function for closing it
                return this.androidPopoverScanner.dismiss();
              }
            }
          ],
        },
        backdropDismiss: false,
      });
    } else {
      if (this.environmentService.getEnvironmentProperty('browserRfid')) {
        this.helperService.showAlert({
          header: 'Type your tag id :',
          inputs: [
            {
              placeholder: 'tag id',
              id: 'superRfidTag'
            },
          ],
          buttons: [
            {
              text: this.langService.t('general.no'),
              handler: () => {
                return this.triggerTagChange(undefined);
              }
            },
            {
              text: this.langService.t('general.yes'),
              handler: () => {
                const tag = (<HTMLInputElement>document.getElementById('superRfidTag')).value;
                this.triggerTagChange(tag);
              }
            }
          ]
        });
      }
    }
  }

  protected readonly RfidScanMethod = RfidScanMethod;
}
