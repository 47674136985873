import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared.module';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';

import { UserTasksComponent } from './user-tasks.component';

import { FilterFormComponent } from './filters/filter-form/filter-form.component';
import { FilterByTypeComponent } from './filters/filter-by-type/filter-by-type.component';
import { InputCheckboxChipsComponent } from '../input-checkbox-chips/input-checkbox-chips.component';

import { ListComponent } from './list/list.component';
import { TaskComponent } from './list/task/task.component';
import { ContentComponent } from './list/task/content/content.component';
import { DeviationComponent } from './list/task/content/deviation/deviation.component';
import { BreadcrumbComponent } from './list/task/breadcrumb/breadcrumb.component';
import { NameComponent } from './list/task/name/name.component';
import { InfoComponent } from './list/task/info/info.component';
import { FooterComponent } from './list/task/footer/footer.component';
import { OngoingStatusComponent } from './list/task/ongoing-status/ongoing-status.component';
import { CheckOngoingStatusComponent } from './list/task/ongoing-status/check-ongoing-status/check-ongoing-status.component';
import { ServiceOngoingStatusComponent } from './list/task/ongoing-status/service-ongoing-status/service-ongoing-status.component';
import { DueTriggerComponent } from './list/task/footer/due-trigger/due-trigger.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    SharedPipesModule,
    FormsModule
  ],
  declarations: [
    UserTasksComponent,
    FilterFormComponent,
    FilterByTypeComponent,
    ListComponent,
    TaskComponent,
    InputCheckboxChipsComponent,
    ContentComponent,
    DeviationComponent,
    BreadcrumbComponent,
    NameComponent,
    InfoComponent,
    FooterComponent,
    OngoingStatusComponent,
    CheckOngoingStatusComponent,
    ServiceOngoingStatusComponent,
    DueTriggerComponent,
  ],
  exports: [
    UserTasksComponent,
    FilterFormComponent,
    FilterByTypeComponent,
    ListComponent,
  ],
})
export class UserTasksModule {}

