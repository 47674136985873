import {Component, EventEmitter, Output} from '@angular/core';
@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent {

  @Output() retryLoading: EventEmitter<void> = new EventEmitter();
  constructor() { }

  public readonly tel = '+46-8-732 35 00';
  public readonly email = 'support@checkproof.com';

  retryInit(): void  {
    this.retryLoading.emit();
  }

  public sendEmailToSupport(): void {
    window.location.href = 'mailto:' + this.email;
  }

  public callToTheSupport(): void {
    window.location.href = 'tel:' + this.tel;
  }

}
