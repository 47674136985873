import { Injectable } from '@angular/core';

interface IParamsSet {
  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export default class RouteService {
  /** Available paths */
  public readonly PATHS = {
    units: {
      unit: '/units/{id}',
      checklistVariants: '/units/{id}/checklistvariants',
    },
    rounds: {
      round: '/rounds/{id}',
      checklistVariants: '/rounds/{id}/checklistvariants',
    },
    serviceInterval: {
      serviceInterval: '/serviceintervals/{id}',
      batches: {
        list: '/serviceintervals/batches'
      }
    },
    services: {
      batches: {
        list: '/services/batches',
      }
    },
    checklistVariants: {
      batch: {
        list: '/checklist-variants/batches',
      }
    },
    checks: {
      batches: {
        list: '/checks/batches',
      },
      export: '/checks/{id}/export',
      share: '/checks/{id}/share',
    },
    checksongoing: '/checksongoing/{id}',
    positions: {
      batches: {
        list: '/positions/batches',
      }
    },
    inspectionsCertificates: {
      intervals: '/{objectType}s/{objectID}/inspectionintervals',
      performed: '/{objectType}s/{objectID}/inspections',
    },
    user: {
      me: {
        tasks: {
          deviation: '/users/me/tasks/deviations'
        }
      },
      usersInfo: '/users/info'
    },
    responsibles: {
      batches: '/responsibles/batches'
    },
    deviations: {
      batch: {
        list: '/deviations/batches',
      },
      deviation: '/deviations/{id}',
    },
    independentDeviations: {
      batch: {
        list: '/independentdeviations/batches',
      },
      deviation: '/independentdeviations/{id}',
    },
    planningTool: {
      tasks: {
        activeTaskWithHistory: 'planning-tool/active-task-with-history',
        taskWithHistory: 'planning-tool/task-with-history',
        task: 'planning-tool/tasks/{id}',
        list: 'planning-tool/filter-tasks',
      }
    },
    odometers: {
      unitOdometers: '/units/{unitID}/odometers',
    },
    tokens: {
      academy: '/tokens/academy'
    },
    publicTokens: {
      getByToken: '/publictokens/token/{token}'
    },
    publicUsers: {
      sendCode: '/publicusers/sendcode',
      updateProfile: '/publicusers/me',
    },
    notifications: {
      system: {
        list: '/users/me/notifications/system',
        read: '/users/me/notifications/system/{id}/read'
      }
    },
    rfid: {
      tags: {
        positions: '/rfid/tags/{tag_uid}/{objectType}/{objectId}/positions',
        categories: '/rfid/tags/{tag_uid}/{objectType}/{objectId}/categories',
      }
    }
  };

  /** Fill template of URL by parameters */
  public makeUrl(template: string, parameters: IParamsSet = {}): string {
    const query = {};
    return Object.keys(parameters) // Make url
        .reduce(
          (url, key) => {
            if (-1 === url.indexOf(`{${key}}`)) {
              query[key] = parameters[key];
            }
            return url.replace(`{${key}}`, parameters[key]);
          },
          template
        )
      + this.encodeQuery(query);
  }

  /** Encode query search parameters */
  private encodeQuery(query: IParamsSet): string {
    const keys = Object.keys(query);

    return keys.length
      ? '?' + keys
      .map(
        key => Array.isArray(query[key])
          ? RouteService.encodeArray(key, query[key])
          : RouteService.encodeScalar(key, query[key])
      )
      .join('&')
      .replace(/&$/, '')
      : '';
  }

  /** Encode scalar query parameter */
  private static encodeScalar(key: string, value: any): string {
    return `${key}=${encodeURIComponent(encodeURIComponent(value))}`;
  }

  /** Encode array query parameter */
  private static encodeArray(key: string, value: any[]): string {
    return value.reduce(
      (params, item) => params.concat(
        encodeURIComponent(`${key}[]`) + '=' + encodeURIComponent(item)
      ),
      []
    )
      .join('&');
  }
}
