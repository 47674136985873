import { Component, OnInit, OnChanges, Input, Output, EventEmitter, forwardRef, ViewChild, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { UserAppPreferencesService } from '../../services/user-app-preferences.service';
import { ObjectableUtil } from '../../utils/objectable.util';
import { LangService } from '../../services/lang.service';
import { ArrayUtil } from '../../utils/array.util';
import { Subject } from 'rxjs/internal/Subject';
import * as _ from 'lodash';

@Component({
  selector: 'app-select-unit-round',
  templateUrl: './select-unit-round.component.html',
  styleUrls: ['./select-unit-round.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectUnitRoundComponent),
      multi: true,
    },
  ],
})
export class SelectUnitRoundComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @ViewChild('objSelectDrop', { static: false }) objSelectDrop;

  public isDisabled = false;
  public objectsPromise;
  public objectsLoading;

  private currentNumberDataFetched: number = undefined;
  private totalNumberDataFetched: number = undefined;

  private maxObjects = 100;
  private itemsPerPage = 20;
  private favorites;
  private value;
  private selectedWorkplaces = [];
  private selectedSections = [];
  private reinitOnClose = false;
  private requestOptions: any = { page: 1, unitsOnly: true };
  private units = [];
  private rounds = [];
  private debouncer = new Subject<any>();
  private initialized = false;
  public allObjectsChecked = false;

  @Input() dependables;
  @Input() unitsOnly = false;
  @Input() roundsOnly = false;
  @Input() multiple = false;
  @Input() writableOnly = true;
  @Input() company = null;
  @Input() rfidTag = null;
  @Input() showChecksStatusBar = false;
  @Input() isRequired = false;
  @Input() withSelectAllButton = false;
  @Input() withEmptySection = false;
  @Input() preset = { workplaces: [], sections: [], objects: [], allObjectsChecked: false, allSectionsChecked: false, withEmptySection: false };
  @Input() withFavorite = true;
  @Input() objectInUse = 'all';
  @Output() changed = new EventEmitter();
  @Output() allObjectsCheckedChange = new EventEmitter();

  constructor(private langService: LangService, private apiService: ApiService, private userAppPreferencesService: UserAppPreferencesService) {
    this.debouncer.subscribe(value => {
      if ((this.currentNumberDataFetched === undefined && this.totalNumberDataFetched === undefined) || this.currentNumberDataFetched < this.totalNumberDataFetched) {
        this.fetchObjects(value);
      }
    });
  }

  async ngOnInit() {
    await this.fetchObjects({ unitsOnly: this.unitsOnly, roundsOnly: this.roundsOnly });
    if (this.preset) {
      this.allObjectsChecked = this.preset.allObjectsChecked;
      this.withEmptySection = this.preset.withEmptySection;
      if (this.preset?.objects?.length > 0) {
        this.selectedObject = this.preset?.objects;
        this.onObjectChange();
      }
    }
    this.initialized = true;
  }

  ngOnChanges(changes) {
    // Delay onChanges calls until fully initialized.
    if (!this.initialized) {
      setTimeout(() => this.ngOnChanges(changes), 100);

      return;
    }

    if (changes.company && changes.company.previousValue) {
      this.selectedObject = null;
      this.favorites = [];
      this.resetRequestOptions();
      this.fetchObjects(this.requestOptions);
    } else if (changes.showChecksStatusBar && changes.showChecksStatusBar.previousValue !== undefined) {
      this.favorites = null; // refetch favorites as well to get check variants
      this.resetRequestOptions();
      this.fetchObjects(this.requestOptions);
    } else if (this.dependables) {
      this.updateDependables();
    }

    if (changes.rfidTag && this.rfidTag) {
      this.selectedObject = null;
      this.favorites = [];
      this.resetRequestOptions();
      this.fetchObjects(this.requestOptions);
    }

    if (changes.withEmptySection && changes.withEmptySection.currentValue !== changes.withEmptySection.previousValue) {
      this.withEmptySection = changes.withEmptySection.currentValue;
      this.resetRequestOptions();
      this.fetchObjects(this.requestOptions);
    }
  }

  ngOnDestroy(): void {
    this.debouncer.unsubscribe();
  }

  /**
   * get accessor for selectedObject
   * @return
   */
  get selectedObject(): any {
    return this.value;
  }

  /**
   * set accessor for selectedObject
   * @param v
   */
  set selectedObject(v: any) {
    if (v !== this.value) {
      this.value = v;
      this._onChange(v);
    }
  }

  writeValue(obj: any): void {
    this.selectedObject = obj;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /**
   * Call if input was "touched"
   * @private
   */
  private _onTouched = () => {};

  /**
   * Call if value was changed inside our component
   * @param _
   * @private
   */
  private _onChange = (_: any) => {};

  /**
   * triggers when selected object is changes
   */
  public onObjectChange() {
    this.changed.emit();
  }

  /**
   * triggers when searching an object
   * @param term
   */
  public onObjectsSearch(term) {
    this.resetRequestOptions();
    if (term) {
      this.fetchObjects(Object.assign(this.requestOptions, { search: term }));
    } else {
      this.fetchObjects(this.requestOptions);
    }
  }

  /**
   * triggers when object dropdown list is closed
   */
  public onSelectClose() {
    if (this.reinitOnClose) {
      this.fetchObjects();
    }
  }

  /**
   * add/remove item in favorites list
   * @param event
   * @param type
   * @param item
   */
  public toggleFavorite(event, type, item) {
    event.stopPropagation();

    item.isFavorite = !item.isFavorite;

    const favoritesType = ObjectableUtil.isUnit(item) ? 'unit' : 'round';

    if (item.isFavorite) {
      this.userAppPreferencesService.addToFavorites(favoritesType, item);

      if (!this.favorites) {
        this.favorites = [];
      }
      this.favorites.push(item);
    } else {
      this.userAppPreferencesService.removeFromFavorites(favoritesType, item);
      this.favorites = this.favorites.filter(favorite => !ObjectableUtil.isSameObjects(item, favorite));
    }

    this.reinitOnClose = true;
  }

  /**
   * get list of objects
   * @param options
   */
  private async fetchObjects(options = <any>{}) {
    this.reinitOnClose = false;
    // unselect currently selected object if it doesn't match selected workplace or section
    const defaultOptions = <any>{
      compact: true,
      per_page: this.itemsPerPage,
      objectInUse: this.objectInUse,
    };

    defaultOptions.allowEmptySections = this.withEmptySection;

    if (this.selectedObject && this.selectedWorkplaces.length) {
      const selectedWorkplaceIds = this.selectedWorkplaces.map(selectedWorkplace => selectedWorkplace.id);
      const selectedSectionIds = this.selectedSections.map(selectedSection => selectedSection.id);

      let selectedObjects = [];
      if (this.multiple) {
        selectedObjects = this.selectedObject && this.selectedObject.length ? this.selectedObject : [];
      } else {
        selectedObjects = [this.selectedObject];
      }

      selectedObjects = selectedObjects.filter(object => {
        if (selectedWorkplaceIds.indexOf(object.workplace_id) < 0) {
          return false;
        }

        // Only in case we have selectedSectionIds list and no sections preset we need to apply filter
        if (selectedSectionIds.length && this.preset?.sections?.length === 0) {
          if (!object.section || !object.section.length || selectedSectionIds.indexOf(object.section[0].id) < 0) {
            return false;
          }
        }

        return true;
      });

      if (this.multiple) {
        this.selectedObject = selectedObjects;
      } else if (!selectedObjects.length) {
        this.selectedObject = null;
      }
    }

    const favoritesOptions = <any>{
      compact: true,
      objectInUse: 'all',
    };
    if (this.unitsOnly) {
      defaultOptions.unitsOnly = true;
      favoritesOptions.unitsOnly = true;
    }
    if (this.roundsOnly) {
      defaultOptions.unitsOnly = false;
      defaultOptions.roundsOnly = true;
      favoritesOptions.roundsOnly = true;
    }
    if (this.writableOnly) {
      defaultOptions.writable = true;
      favoritesOptions.writable = true;
    }
    if (this.selectedWorkplaces && this.selectedWorkplaces.length) {
      defaultOptions.workplaces = this.selectedWorkplaces.map(selectedWorkplace => selectedWorkplace.id);

      if (this.selectedSections) {
        defaultOptions.sections = this.selectedSections.map(selectedSection => selectedSection.id);
      }
    }
    if (this.company && this.company.id) {
      defaultOptions.user_id = this.company.id;
      favoritesOptions.user_id = this.company.id;
    }
    if (this.showChecksStatusBar && defaultOptions.workplaces && defaultOptions.workplaces.length) {
      defaultOptions.appends = 'checklistDetails';
      favoritesOptions.appends = 'checklistDetails';
      delete defaultOptions.per_page; // load all units and rounds
    }
    if (this.rfidTag && this.rfidTag.uid) {
      defaultOptions.rfid_tag_uid = this.rfidTag.uid;
    }

    options = Object.assign(defaultOptions, options);

    const favoritePromises = [];
    if (!this.roundsOnly) {
      favoritePromises.push(this.userAppPreferencesService.getFavoriteIds('unit'));
    }
    if (!this.unitsOnly) {
      favoritePromises.push(this.userAppPreferencesService.getFavoriteIds('round'));
    }

    this.objectsLoading = true;
    await Promise.all(favoritePromises).then(async favorites => {
      const unitFavoriteIds = favorites[0] && favorites[0].length ? favorites[0] : [];
      const roundFavoriteIds = favorites[1] && favorites[1].length ? favorites[1] : [];

      const promises = [this.apiService.get('/objects', options, { cache: true })];

      if (this.favorites) {
        promises.push(Promise.resolve(this.favorites));
      } else if (unitFavoriteIds.length || roundFavoriteIds.length) {
        if (unitFavoriteIds.length) {
          favoritesOptions.units = unitFavoriteIds;
        }
        if (roundFavoriteIds.length) {
          favoritesOptions.rounds = roundFavoriteIds;
        }
        promises.push(this.apiService.get('/objects', favoritesOptions, { cache: true }));
      } else {
        promises.push(Promise.resolve([]));
      }

      return await Promise.all(promises).then(results => {
        this.objectsLoading = false;
        let objects = results[0];
        const request = results[0];
        this.favorites = results[1];
        this.currentNumberDataFetched = objects?.to !== null ? objects?.to : objects?.total;
        this.totalNumberDataFetched = objects?.total;
        if (objects.hasOwnProperty('data')) {
          objects = objects.data;
        }

        const groupedObjects = [];
        const units = [];
        const rounds = [];

        objects.forEach(object => {
          if (ObjectableUtil.isUnit(object)) {
            units.push(object);
          } else {
            rounds.push(object);
          }
        });

        if (this.favorites && this.favorites.length) {
          const favoriteObjects = this.favorites.filter(favorite => {
            favorite.isFavorite = true;

            if (options.workplaces && options.workplaces.length) {
              if (options.workplaces.indexOf(favorite.workplace_id) < 0) {
                return false;
              }

              if (options.sections && options.sections.length) {
                if (!favorite.section || !favorite.section.length || options.sections.indexOf(favorite.section[0].id) < 0) {
                  return false;
                }
              }
            }

            if (!options.search) {
              return true;
            }

            return favorite.name.toLowerCase().indexOf(options.search.toLowerCase()) >= 0;
          });

          if (favoriteObjects.length) {
            groupedObjects.push({
              title: this.langService.t('general.favorites'),
              objects: favoriteObjects.map(object => this.processObject(object)),
            });
          }
        }

        if (units.length || this.units.length) {
          const unitsNotFavorite = units.filter(unit => unitFavoriteIds.indexOf(unit.id) < 0);
          this.units.push(...unitsNotFavorite.map(object => this.processObject(object)));
          if (this.units.length) {
            groupedObjects.push({
              title: this.langService.t('units.plural'),
              objects: _.uniqBy(this.units, obj => obj.id), // remove duplicate object if existing in the list
            });
          }
        }
        if (rounds.length || this.rounds.length) {
          const roundsNotFavorite = rounds.filter(round => roundFavoriteIds.indexOf(round.id) < 0);
          this.rounds.push(...roundsNotFavorite.map(object => this.processObject(object)));
          if (this.rounds.length) {
            groupedObjects.push({
              title: this.langService.t('rounds.plural'),
              objects: _.uniqBy(this.rounds, obj => obj.id), // remove duplicate object if existing in the list
            });
          }
        }

        if (this.rfidTag && this.rfidTag.uid && (units.length || rounds.length)) {
          this.objSelectDrop.toggleDropdown();
        }

        this.requestOptions = Object.assign(this.requestOptions, options);
        if (!this.roundsOnly && !this.unitsOnly && !this.requestOptions.getRounds && request.page && request.current_page >= request.last_page) {
          this.requestOptions = Object.assign(this.requestOptions, {
            getRounds: true,
            page: 1,
            unitsOnly: false,
            roundsOnly: true,
          });
          this.debouncer.next(this.requestOptions);
        }
        this.objectsPromise = groupedObjects;
      });
    });
  }

  /**
   * Process an object to add some specific data
   * @param object
   * @return
   */
  private processObject(object) {
    if (!this.showChecksStatusBar) {
      return object;
    }

    const status = {
      completed: [],
      ongoing: [],
      upcoming: [],
      overdue: [],
    };

    if (object.checklistDetails && object.checklistDetails.checklist_variants) {
      object.checklistDetails.checklist_variants.forEach(variant => {
        if (variant.ongoing_checks_count) {
          status.ongoing.push('ongoing');
        } else if (variant.due_data.is_due) {
          status.overdue.push('overdue');
        } else if (variant.due_data.is_completed) {
          status.completed.push('completed');
        } else {
          status.upcoming.push('upcoming');
        }
      });
    }

    let statuses = [];
    for (const prop in status) {
      if (status.hasOwnProperty(prop)) {
        statuses = statuses.concat(status[prop]);
      }
    }

    object.checkStatuses = statuses;

    return object;
  }

  private updateDependables() {
    let shouldRefetch = false;

    let dependedWorkplace = null;
    if (this.dependables.hasOwnProperty('workplace')) {
      dependedWorkplace = this.dependables.workplace;
    } else if (this.dependables.hasOwnProperty('workplace_id')) {
      dependedWorkplace = {
        id: this.dependables.workplace_id,
      };
    }
    if (ArrayUtil.isDifferentArrays(dependedWorkplace, this.selectedWorkplaces)) {
      if (dependedWorkplace) {
        this.selectedWorkplaces = Array.isArray(dependedWorkplace) ? dependedWorkplace : [dependedWorkplace];
      } else {
        this.selectedWorkplaces = [];
      }

      shouldRefetch = true;
    }

    let dependedSection = null;
    if (this.dependables.hasOwnProperty('section')) {
      dependedSection = this.dependables.section;
    } else if (this.dependables.hasOwnProperty('section_id')) {
      dependedSection = {
        id: this.dependables.section_id,
      };
    }
    if (ArrayUtil.isDifferentArrays(dependedSection, this.selectedSections)) {
      if (dependedSection) {
        this.selectedSections = Array.isArray(dependedSection) ? dependedSection : [dependedSection];
      } else {
        this.selectedSections = [];
      }

      shouldRefetch = true;
    }

    if (shouldRefetch) {
      this.resetRequestOptions();
      this.fetchObjects(this.requestOptions);
    }
  }

  private resetRequestOptions() {
    this.requestOptions = { page: 1 };
    this.units = [];
    this.rounds = [];
  }

  public scrolled() {
    this.requestOptions.page = this.requestOptions.page + 1;
    this.debouncer.next(this.requestOptions);
  }

  public async onAllObjectsChecked() {
    this.selectedObject = [];
    this.allObjectsCheckedChange.emit(this.allObjectsChecked);
  }
}
