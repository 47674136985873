import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { AuthService } from '../../services/auth.service';
import { EnvironmentService } from '../../services/environment.service';
import { EventsService } from '../../services/events.service';
import { SamlAuthService } from '../../services/saml-auth.service';
@Component({
  selector: 'app-side-bar-menu-public',
  templateUrl: './side-bar-menu-public.component.html',
  styleUrls: ['./side-bar-menu-public.component.scss']
})
export class SideBarMenuPublicComponent implements OnInit, OnDestroy {
  public appVersionNumber: string;
  public isEnabled = false;
  public isOfflineMode = false;
  public isNativePlatform: boolean;
  public user;

  constructor(
    private sideMenu: MenuController,
    public events: EventsService,
    private authService: AuthService,
    private environmentService: EnvironmentService,
    public samlAuthService: SamlAuthService,
  ) {}

  async ngOnInit() {
    const environment = this.environmentService.getEnvironment();
    
    this.user = await this.authService.getCurrentUser(true);

    this.appVersionNumber = environment.appVersion;

    this.events.subscribe('sideMenu:open', async () => {
      this.user = await this.authService.getCurrentUser(true);
      this.open();
    });

    // Subscribing to logout event and disable sidebar menu
    this.events.subscribe('user:logout', () => {
      this.setDisabled();
    });

    // Subscribing to user init event and triggers menu initializeMenu
    this.events.subscribe('user:init', () => {
      this.initializeMenu();
    });

    this.events.subscribe('offline:activated', (active) => {
      this.isOfflineMode = active;
    });

    // Set sidebar menu as disabled when not authenticated
    this.authService.isAuthenticated()
      .then(loggedIn => {
        if (!loggedIn) {
          this.setDisabled();
        } else {
          this.initializeMenu();
        }
      });
  }

  ngOnDestroy() {
    this.events.unsubscribe('sideMenu:open');
    this.events.unsubscribe('user:init');
    this.events.unsubscribe('user:logout');
  }

  /**
   * Initialize sidebar menu, enables it and checks if user has clones to enable switching user functionality
   *
   */
  private async initializeMenu(): Promise<any> {
    this.setDisabled(false);
  }

  /**
   * Closing sidebar menu
   */
  public close(): void {
    this.sideMenu.close('sidebar-menu');
  }

  /**
   * Opens sidebar menu
   */
  public open(): void {
    this.sideMenu.open('sidebar-menu');
  }

  /**
   * Listen to logout event sent from view
   */
  public logout(): void {
    this.close();

    this.authService.attemptLogout().then(result => {
      if (result === true) {
        // Disable menu when logging out
        this.setDisabled();
      }
    });
  }

  /**
   * Set side bar menu disabled/enabled
   * @param disabled
   */
  public setDisabled(disabled = true) {
    this.isEnabled = !disabled;
    this.sideMenu.enable(!disabled, 'sidebar-menu');
  }
}
