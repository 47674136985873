import { Component } from '@angular/core';
import { Platform, NavController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { ActivatedRoute, Router } from '@angular/router';
import { App } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';

import { AuthService } from './services/auth.service';
import { HelperService } from './services/helper.service';
import { AppInitService } from './services/app-init.service';
import { LangService } from './services/lang.service';
import { ToastService } from './services/toast.service';
import { ApiNotAvailableError } from './utils/error.util';

import { Location } from '@angular/common';
import { parseUrl } from './utils/url.util';
import { GeolocationService } from './services/geolocation.service';

import { register } from 'swiper/element/bundle';
register();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public isInitialized = false;
  public isInitializationFailed = false;
  public initErrorMessage = '';
  public isIos = false;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private platform: Platform,
    private authService: AuthService,
    private helperService: HelperService,
    private appInitService: AppInitService,
    private langService: LangService,
    private toastService: ToastService,
    protected navController: NavController,
    private geolocationService: GeolocationService,
  ) {
    this.showSplashScreen()
      .then(async () => {
        this.initializeApp()
        .finally(async () => {
          await this.hideSplashScreen();
        });
      })
      .catch(() => {});
  }

  /**
   * Initialize application
   * @return {Promise<any | void>}
   */
  private initializeApp(): Promise<any | void> {
    return new Promise<void>((resolve, reject) => {
      this.platform.ready().then(() => {
        this.initAppEvents();
        /**
         * If device is IOS render numberic keyboard
         */
        this.checkIfIos();

        return this.authService.isAuthenticated().then( loggedIn => {
          this.appInitService.initServices()
            .then(() => {
              return this.appInitService.baseInit();
            })
            .then(() => {
              const routeSnapshot = this.route.snapshot;
              let jwt = routeSnapshot.queryParams['jwt'] || null;
              if (jwt === null && this.location.path().indexOf('?jwt=') !== -1) {
                jwt = this.location.path().slice(this.location.path().indexOf('?jwt=') + 5);
              }

              if (!jwt) {
                return loggedIn;
              }

              const type = routeSnapshot.queryParams['type'] || null;
              return this.handleJwt(jwt, type);
            })
            .then((loggedIn) => {
              if (loggedIn) {
                return this.appInitService.initLoggedInUser();
              } else {
                return this.appInitService.initNotLoggedInUser();
              }
            })
            .then(() => {
              this.isInitialized = true;
              this.isInitializationFailed = false;
              this.helperService.setInitialized();
              this.runApp();

              // Clear jwt from url.
              let path = this.location.path();
              if (path.indexOf('?jwt=') !== -1) {
                path = path.slice(1, path.indexOf('?'));
                if (path.length === 0) {
                  path = 'home';
                }
                const parsedUrl = parseUrl('/' + path);
                this.navController.navigateRoot([parsedUrl.pathname, parsedUrl.params]);
              }

              resolve();
            })
            .catch((error) => {
              console.error('app.component.ts initServices() catch error', error);
              if (error.message === 'access_denied') {
                this.authService.showAccessDeniedAlert().then(() => {
                  this.retryInit();
                });
              } else if (error.status === 401) { // unauthorized/invalid token
                this.authService.logout().then(() => {
                  this.retryInit().then(() => {
                    this.toastService.showAccessDeniedNotification();
                  });
                });
              } else if (error instanceof ApiNotAvailableError) {
                this.initErrorMessage = error.message;
                this.isInitializationFailed = true;
              } else {
                this.isInitializationFailed = true;
              }

              reject(error.message);
            });
        });
      });
    });
  }

  /**
   * Retry the entire init process
   */
  public async retryInit() {
    await this.helperService.showLoader();
    this.toastService.closeErrorNotifications();
    return this.initializeApp()
      .finally(() => {
        this.helperService.dismissLoader();
      });
  }

  /**
   * Run application
   */
  private runApp(): void {
    this.checkGeolocationService();
    this.helperService.setDefaultStatusBar();
    this.appInitService.onAppInit();
  }

  /**
   * Show splash screen or spinner
   * @return {Promise<any>}
   */
  private async showSplashScreen(): Promise<any> {
    if (Capacitor.isNativePlatform()) {
      await SplashScreen.show({
        autoHide: false
      });
      return Promise.resolve();
    } else {
      return this.helperService.showLoader();
    }
  }

  /**
   * Hide splash screen or spinner
   */
  private async hideSplashScreen(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      await SplashScreen.hide();
    } else {
      await this.helperService.dismissLoader();
    }
  }

  /**
   * Check status of geo-service by getting current geo-location
   */
  checkGeolocationService() {
    this.geolocationService.getGeoPosition();
  }

  /**
   * Init device app events
   */
  private initAppEvents() {
    App.addListener('appStateChange', (state) => {
      if (state.isActive) {
        this.appInitService.restoreApp().catch((err) => {
          console.error(err);
        });
      }
    });

    App.addListener('appUrlOpen', (data) => {
      if (data && data.url) {
        if (!this.isInitialized) {
          this.appInitService.runAfterAppInit(() => {
            return this.handleExternalUrl(data.url);
          });
        } else {
          this.handleExternalUrl(data.url);
        }
      }
    });
  }

  /**
   * Handle external urls (deep/univeral/app links)
   * @param url
   */
  private async handleExternalUrl(url): Promise<boolean> {
    const parsedUrl = parseUrl(url);
    if (parsedUrl && parsedUrl.query && parsedUrl.query.jwt) {
      await this.helperService.showLoader();
      const isValid = await this.handleJwt(parsedUrl.query.jwt, (parsedUrl.query.type || null), true);
      await this.helperService.dismissLoader();

      if (!isValid) {
        this.navController.navigateRoot(['/home']);
        return false;
      }
    }

    this.navController.navigateRoot([parsedUrl.pathname, parsedUrl.params]);
    return true;
  }

  /**
   * Handle JWT auth
   * @param jwt
   * @param type
   * @param initLoggedIn
   */
  private async handleJwt(jwt, type, initLoggedIn = false): Promise<boolean> {
    const loggedIn = await this.authService.isAuthenticated();

    if (!type) {
      type = this.helperService.protocol.ExternalTypes.BEEKEEPER;
    } else if (type === 'saml') {
      type = this.helperService.protocol.ExternalTypes.SCIM;
    }

    if (loggedIn) {
      try {
        if (await this.authService.getJwtUserSame(jwt)) {
          return true;
        }

        await this.authService.logout(null);
      } catch (e) {
        return false;
      }
    }

    try {
      await this.authService.loginJWT(jwt, type);
    } catch (e) {
      return false;
    }

    if (!initLoggedIn) {
      return true;
    }

    try {
      await this.appInitService.initLoggedInUser();
    } catch (e) {
      return false;
    }

    return true;
  }

  public get isPublicUser(): boolean {
    return this.authService.isPublicUser();
  }

  /**
   * Check if platform is ios
   */
  private checkIfIos(): boolean {
    if (Capacitor.getPlatform() === 'ios') {
      return this.isIos = true;
    }
  }
}
