import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { IOAuthResponse } from '../interfaces/oauth-response.interface';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AccessTokenService {
  private accessToken?: string;
  private refreshToken?: string;
  private expireAt?: number;
  private timeToRefresh?: number;
  private refreshTokenPromise = null;

  // Keys for local storage
  private readonly tokenKey = 'token';
  private readonly tokenRefreshKey = 'token_refresh';
  private readonly tokenExpireAtKey = 'token_expires_at';

  private authService: AuthService;

  constructor(
    private storage: Storage
  ) {}

  /**
   * Init service before use it
   */
  public async initService(): Promise<any> {
    return this.loadFromStorage();
  }

  /**
   * Set instance of AuthService (can't be injected because of loop dependency)
   */
  public setAuthService(authService: AuthService) {
    this.authService = authService;
  }

  /**
   * Set token info from OauthResponse
   */
  public async setOauthToken(oauth: IOAuthResponse): Promise<any> {
    this.accessToken = oauth.access_token;
    this.refreshToken = oauth.refresh_token;
    this.expireAt = new Date((new Date().getTime()) + oauth.expires_in * 1000).getTime();
    this.timeToRefresh = null;

    return this.saveToStorage();
  }

  /**
   * Get access token with verification
   */
  public async getAccessToken(): Promise<string> {
    // before returning access token - check expire date and refresh token if needed
    await this.checkAccessToken();

    return this.accessToken;
  }

  /**
   * Check expire date of token and refreshes it if needed
   */
  public async checkAccessToken(): Promise<void> {
    if (!this.authService || !this.accessToken || !this.refreshToken || !this.expireAt) {
      return;
    }

    if (Date.now() < this.getTimeToRefresh()) {
      // not yet expired
      return;
    }

    // in case if new refreshed token already requested (avoid two refresh requests at the same time)
    if (this.refreshTokenPromise) {
      return this.refreshTokenPromise;
    }

    // send request to obtain fresh access token and save promise
    this.refreshTokenPromise = this.authService.refreshToken(this.refreshToken);
    try {
      await this.refreshTokenPromise;
    } finally {
      // clear saved promise
      this.refreshTokenPromise = null;
    }
  }

  /**
   * Clear all properties and saved properties from storage
   */
  public async clear(): Promise<any> {
    this.refreshToken = this.accessToken = this.expireAt = this.timeToRefresh = null;
    await this.removeFromStorage();
  }

  /**
   * Save properties to storage
   */
  private saveToStorage(): Promise<any> {
    return Promise.all([
      this.storage.set(this.tokenKey, this.accessToken),
      this.storage.set(this.tokenRefreshKey, this.refreshToken),
      this.storage.set(this.tokenExpireAtKey, this.expireAt),
    ]);
  }

  /**
   * Load saved properties from storage
   */
  private loadFromStorage(): Promise<any> {
    return Promise.all([
      this.storage.get(this.tokenKey).then(res => this.accessToken = res),
      this.storage.get(this.tokenRefreshKey).then(res => this.refreshToken = res),
      this.storage.get(this.tokenExpireAtKey).then(res => this.expireAt = res),
    ]);
  }

  /**
   * Remove saved properties from storage
   */
  private removeFromStorage(): Promise<any> {
    return Promise.all([
      this.storage.remove(this.tokenKey),
      this.storage.remove(this.tokenRefreshKey),
      this.storage.remove(this.tokenExpireAtKey),
    ]);
  }

  /**
   * Get timestamp when token should be refreshed
   */
  private getTimeToRefresh(): number {
    if (this.timeToRefresh) {
      return this.timeToRefresh;
    }

    this.timeToRefresh = this.expireAt - this.getTokenRefreshOffset();
    return this.timeToRefresh;
  }

  /**
   The offset time in milliseconds to refresh the access token. This must
   return a random number. This randomization is needed because in case of
   multiple browser tabs (ONLY for web-app), we need to prevent the tabs from sending refresh token
   request at the same exact moment.
   @default a random number between 5 and 10
   */
  private getTokenRefreshOffset(): number {
    const min = 5;
    const max = 10;

    return (Math.floor(Math.random() * (max - min)) + min) * 1000;
  }
}
