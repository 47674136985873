import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, forwardRef, ContentChild, TemplateRef, ViewChild, Optional } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormGroupDirective } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { FormValidate } from '../form-validate';
import { LangService } from '../../services/lang.service';
import { SelectDropdownService } from './select-dropdown.service';
import { StyleUtil } from '../../utils/style.util';

@Component({
  selector: 'app-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectDropdownComponent),
      multi: true,
    },
  ],
})
export class SelectDropdownComponent extends FormValidate implements OnInit, OnDestroy, ControlValueAccessor {
  public isDisabled = false;
  public searchTypeahead;
  public isItemsPromise = false;
  public hideSelected = false;
  public searchValue = '';
  public isFiltered = false;
  public isDropdownOpen = false;
  public options = [];
  public selectedOptions = [];
  public sdService: SelectDropdownService;

  private selectedItem;

  @Input() multiple = false;
  @Input() placeholder = null;
  @Input() notFoundText = null;
  @Input() items;
  @Input() isLoading = false;
  @Input() searchFn;
  @Input() groupBy;
  @Input() bindId = 'id';
  @Input() bindLabel = 'name';
  @Input() bindLabelFunc = null;
  @Input() bindColor = '';
  @Input() clearable = true;
  @Input() disabled = false;
  @Input() addTag = false;
  @Input() addTagText = 'Add item';
  @Input() hasInitialItems = true;
  @Input() typeToSearchText = null;
  @Input() searchable = true;
  @Input() showOverflow = false;
  @Input() isRequired = false;
  @Input() restrictNumberOfSelectedItem = null;

  @Output() typeahead = new EventEmitter();
  @Output() changed = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Output() scrollChanged = new EventEmitter();

  @ContentChild('itemContent', { static: false }) itemContentTmpl: TemplateRef<any>;
  @ViewChild('selectElement', { static: false }) selectElement;

  constructor(private langService: LangService, @Optional() protected controlContainer: FormGroupDirective) {
    super(controlContainer);
    this.sdService = new SelectDropdownService(this);
  }

  ngOnInit() {
    this.initValidator();

    // TODO: enable it for other dropdown selectors
    // right now auto-select-feature controlled only by isRequired property
    // in future we can control it also by "required" validator that is bind to control
    // if (!this.isRequired && this.hasRequiredValidator()) {
    //   this.isRequired = true;
    // }

    if (this.multiple) {
      this.hideSelected = true;
    }

    if (this.items && typeof this.items.then === 'function') {
      this.isItemsPromise = true;
    }

    if (this.addTagText) {
      this.addTagText += ' ';
    }

    if (this.typeahead.observers.length) {
      this.searchTypeahead = new Subject<string>();
      this.searchTypeahead.pipe(debounceTime(400), distinctUntilChanged()).subscribe(term => {
        this.isFiltered = !!term;
        this.typeahead.emit(term);
      });
    }

    if (this.placeholder === null) {
      this.placeholder = this.langService.t('actions.select');
    }
    if (this.notFoundText === null) {
      this.notFoundText = this.langService.t('powerSelect.noMatches');
    }
    if (this.typeToSearchText === null) {
      this.typeToSearchText = this.langService.t('general.search');
    }
  }

  ngOnDestroy(): void {
    this.destroyValidator();
  }

  // get accessor for selected item
  get value(): any {
    return this.selectedItem;
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.selectedItem) {
      this.selectedItem = v;
      this._onChange(v);
    }
  }

  get hasValue() {
    return this.selectedOptions.length > 0;
  }

  writeValue(obj: any): void {
    if (this.shouldSkipFirstWriteValue()) {
      return;
    }

    this.writeValueAsync(obj);
  }

  /**
   * @param obj
   */
  private async writeValueAsync(obj: any): Promise<any> {
    this.selectedItem = obj;

    if (this.isEmptyValue(obj) && this.isRequired) {
      // if field is required, there is no any value set and there only one item - auto-select it
      const autoSelectedValue = await this.getAutoSelectedValue();
      if (autoSelectedValue !== null) {
        this.setSelectedValue(this.multiple ? [autoSelectedValue] : autoSelectedValue);
        this.onSelectionChanged();
        return autoSelectedValue;
      }
    }

    this.setSelectedValue(this.selectedItem);
    return this.selectedItem;
  }

  /**
   * @param value
   */
  private isEmptyValue(value: any): boolean {
    return !value || (this.multiple && Array.isArray(value) && !value.length);
  }

  private async getAutoSelectedValue(): Promise<any> {
    let items = (this.isItemsPromise ? await this.items : this.items) || [];

    if (!items.length) {
      return null;
    }

    if (this.groupBy) {
      let options = [];
      items.forEach(groupItem => {
        if (groupItem[this.groupBy]) {
          options = options.concat(groupItem[this.groupBy]);
        } else {
          options.push(groupItem);
        }
      });
      items = options;
    }

    return items.length === 1 ? items[0] : null;
  }

  /**
   * @param obj
   */
  private setSelectedValue(obj: any): void {
    this.clearSelectedOptions();

    if (obj) {
      const items = Array.isArray(obj) ? obj : [obj];

      items.forEach(item => {
        const option = this.sdService.createOption(item);
        this.selectOption(option, false);
      });
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Call if input was "touched"
   * @private
   */
  private _onTouched = () => {};

  /**
   * Call if value was changed inside our component
   * @param _
   * @private
   */
  private _onChange = (_: any) => {};

  /**
   * triggers when selected item is changed
   */
  public onChange(): void {
    this.changed.emit(this.value);
  }

  /**
   * triggers when select dropdown list is closed
   */
  public onClose() {
    this.searchValue = '';

    // if there is something in search field - trigger to refetch initial items
    if (this.isFiltered && this.hasInitialItems) {
      this.isFiltered = false;
      this.typeahead.emit('');
    }

    this.closed.emit();
  }

  /**
   * Show clear icon
   * @return {boolean}
   */
  public isClearable() {
    return this.clearable && this.hasValue && !this.disabled && !this.multiple;
  }

  /**
   * Toggle open/close dropdown
   */
  public toggleDropdown() {
    if (this.disabled) {
      this.isDropdownOpen = false;
      return;
    }

    if (this.isDropdownOpen) {
      this.closeDropdown();
    } else {
      this.openDropdown();
    }
  }

  /**
   * Open dropdown
   */
  public openDropdown() {
    this.isDropdownOpen = true;
  }

  /**
   * Close dropdown
   */
  public closeDropdown() {
    this.isDropdownOpen = false;
    this.onClose();
  }

  /**
   * Select an option
   * @param option
   * @param {boolean} emitChanges
   */
  public selectOption(option, emitChanges = true) {
    if (!this.multiple) {
      this.clearSelectedOptions();
    }

    option.selected = true;

    this.selectedOptions.push(option);

    if (emitChanges) {
      this.onSelectionChanged();
    }
  }

  /**
   * Unselect an option
   * @param option
   */
  public unselectOption(_) {}

  /**
   * Clear current selected option(s)
   * @param event
   */
  public clearSelected(event) {
    event.stopPropagation();

    this.clearSelectedOptions();

    this.onSelectionChanged();
  }

  /**
   * Unselect one of multiple option
   * @param event
   * @param option
   */
  public unselectMultiOption(event, option) {
    event.stopPropagation();

    option.selected = false;
    this.selectedOptions = this.selectedOptions.filter(selectedOption => {
      return selectedOption !== option;
    });

    this.onSelectionChanged();
  }

  /**
   * Get background and text color based on option
   * @param option
   */
  public getSelectedOptionStyles(option) {
    if (!this.bindColor) {
      return {};
    }

    const styles = <any>{};
    styles.backgroundColor = option.value[this.bindColor] || '#fff';
    if (!StyleUtil.isBrightColor(styles.backgroundColor)) {
      styles.color = '#fff';
    }

    return styles;
  }

  /**
   * Clear all selected options
   */
  private clearSelectedOptions() {
    this.selectedOptions.forEach(option => {
      option.selected = false;
    });
    this.selectedOptions = [];
  }

  /**
   * Trigger when selection is changed
   */
  private onSelectionChanged() {
    if (this.multiple) {
      this.value = this.selectedOptions.map(option => {
        return option.value;
      });
    } else {
      if (this.selectedOptions.length > 0) {
        this.value = this.selectedOptions[0].value;
      } else {
        this.value = null;
      }
    }

    this.onChange();
  }

  public scrolled() {
    this.scrollChanged.emit();
  }
}
