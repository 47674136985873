<ion-app>
  <app-dev-tools></app-dev-tools>
  <div class="status-bar-background" *ngIf="isInitialized"></div>
  <app-side-bar-menu *ngIf="isInitialized && !isPublicUser"></app-side-bar-menu>
  <app-side-bar-menu-public *ngIf="isInitialized && isPublicUser"></app-side-bar-menu-public>
  <ion-router-outlet id="main-content" *ngIf="isInitialized"></ion-router-outlet>
  <app-home-widget *ngIf="isInitialized"></app-home-widget>
  <div *ngIf="isInitializationFailed" class="ion-page app-init-retry">
    <app-maintenance
            (retryLoading)="retryInit()">
    </app-maintenance>
  </div>
</ion-app>
<app-numeric-keyboard *ngIf="isInitialized && isIos"></app-numeric-keyboard>
