import { Component } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { NotificationService } from '../../services/notification.service';
import {INotification} from '../../interfaces/notification.interface';

@Component({
  selector: 'app-privacy-policy-updated',
  templateUrl: './privacy-policy-updated.component.html',
  styleUrls: ['./privacy-policy-updated.component.scss'],
})
export class PrivacyPolicyUpdatedComponent {
  public notification: INotification;

  constructor(
    private modalService: ModalService,
    private notificationService: NotificationService,
  ) { }

  public closeModal() {
    this.notificationService.readSystemNotification(this.notification.id);
    this.modalService.close();
  }

}
